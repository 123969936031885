import useHabitTracker from '../../hooks/useHabitTracker.ts';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import Loader from '../_base/Loader';
import PrimaryText from '../_base/PrimaryText';
import SecondaryText from '../_base/SecondaryText';

const HabitTrackerChallenges = () => {
    const { theme } = useCreatorConfig();
    const { habitGroupStats, isLoadingHabitGroupStats } = useHabitTracker();
    const completedStats = habitGroupStats?.byCompletionStatus?.find(
        ({ completionStatus }) => completionStatus === 'FINISHED',
    );
    const _21DaysCompleted = completedStats?.byDuration?.find(({ duration }) => duration === 21);
    const _30DaysCompleted = completedStats?.byDuration?.find(({ duration }) => duration === 30);

    const stats = [
        {
            title: 'Challenges Completed',
            value: completedStats?.total ?? 0,
            total: 2,
        },
        {
            title: '21 Day Completed',
            value: _21DaysCompleted?.total ?? 0,
            total: 2,
        },
        {
            title: '30 Day Completed',
            value: _30DaysCompleted?.total ?? 0,
            total: 2,
        },
    ];

    if (isLoadingHabitGroupStats)
        return (
            <div className="flex-1  border-2 border-[#FFFFFF1A] rounded-md p-4 flex justify-center items-center">
                <Loader />
            </div>
        );

    return (
        <div className="flex-1 border-2 border-[#FFFFFF1A] rounded-md p-4">
            <PrimaryText inlineBlock className="text-lg font-semibold mb-2">
                Challenges
            </PrimaryText>
            <div className="grid grid-cols-3 gap-4">
                {stats?.map(({ title, value, total }, index) => (
                    <div className="border-2 border-[#FFFFFF1A] rounded-md p-4 flex flex-col items-center justify-center gap-2 text-center" key={index}>
                        <div
                            className="border-2 border-[#FFFFFF1A] rounded-full flex flex-col justify-center items-center w-24 h-24"
                            style={{
                                borderColor:
                                    value === total ? theme?.colours?.primaryColour : theme?.colours?.primaryTextColour,
                            }}>
                            <PrimaryText
                                isSelected={value === total}
                                className="text-lg border-b-2 w-8"
                                style={{
                                    borderColor:
                                        value === total
                                            ? theme?.colours?.primaryColour
                                            : theme?.colours?.primaryTextColour,
                                }}>
                                {value}
                            </PrimaryText>
                            <SecondaryText
                                className="text-lg mt-1"
                                style={{
                                    color:
                                        value === total
                                            ? theme?.colours?.primaryColour
                                            : theme?.colours?.primaryTextColour,
                                }}>
                                {total}
                            </SecondaryText>
                        </div>
                        <PrimaryText
                            className="text-sm"
                            style={{
                                color:
                                    value === total ? theme?.colours?.primaryColour : theme?.colours?.primaryTextColour,
                            }}>
                            {title}
                        </PrimaryText>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HabitTrackerChallenges;
