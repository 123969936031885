import { Auth } from 'aws-amplify';
import environmentConfig from '../environment-config';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

Auth.configure({
    Auth: {
        ...environmentConfig.cognito,
    },
});
