import { useCreatorConfig } from '../../../providers/ConfigProvider';
import useUserAccess from '../../../hooks/useUserAccess.tsx';
import { Link } from 'react-router-dom';
import classNames from '../../../utils/classNames.ts';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { orderBy } from 'lodash';
import PageLoader from '../../_pages/PageLoader';
import PrimaryText from '../../_base/PrimaryText';
import PageBlock from '../../_pages/PageBlock';

const CoursesList = ({ className, block, ...props }: { className?: string; block: PageBlock }) => {
    const { content, theme } = useCreatorConfig();
    const filters = block?.data?.[0]?.filters;
    const { userAccess, isLoading: isLoadingAccess } = useUserAccess();
    const courseItems = orderBy(
        content
            ?.filter(
                ({ type, tags }) =>
                    type === 'Course' &&
                    ((filters?.tags?.length || 0) > 0 ? filters?.tags?.some((tag) => tags.includes(tag)) : true),
            )
            ?.map((contentItem) => ({
                ...contentItem,
                isLocked: !userAccess?.find(({ contentId }) => contentId === contentItem?.id),
            })),
        'isLocked',
        ['asc'],
    );

    return (
        <div className={className} {...props}>
            <PageLoader inline isLoading={isLoadingAccess}>
                <div className={classNames('grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4')}>
                    {courseItems?.length > 0 &&
                        courseItems?.map((contentItem) => {
                            const CoverImage = ({
                                className,
                                isLocked,
                            }: {
                                className?: string;
                                isLocked?: boolean;
                            }) => (
                                <div
                                    key={`cover-image-${contentItem.id}`}
                                    className={classNames(
                                        'relative rounded-xl overflow-hidden ',
                                        !contentItem?.coverImage && 'w-full h-64 flex items-center justify-center px-4',
                                        className,
                                    )}
                                    style={
                                        contentItem?.coverImage
                                            ? {}
                                            : { backgroundColor: theme?.colours?.secondaryColour }
                                    }>
                                    {contentItem?.coverImage ? (
                                        <img
                                            src={contentItem.coverImage}
                                            alt=""
                                            className={classNames('object-cover', isLocked && 'opacity-30')}
                                        />
                                    ) : (
                                        <PrimaryText className="text-lg lg:text-xl font-semibold text-center">
                                            {contentItem.name}
                                        </PrimaryText>
                                    )}
                                    {isLocked && (
                                        <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                                            <LockClosedIcon className="w-16 h-16 text-white" />
                                        </div>
                                    )}
                                </div>
                            );
                            if (contentItem?.isLocked) return <CoverImage isLocked key={`cover-${contentItem.id}`} />;

                            return (
                                <Link
                                    to={`/courses/${contentItem.id}`}
                                    key={`link-${contentItem.id}`}
                                    className="hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out">
                                    <CoverImage />
                                </Link>
                            );
                        })}
                </div>
            </PageLoader>
        </div>
    );
};

export default CoursesList;
