import { PageBlock, PageBlockDataType } from '../../../types/pages.ts';
import classNames from '../../../utils/classNames.ts';
import { useEffect, useState } from 'react';
import addUrlSubstitutions from '../../../utils/addUrlSubstitutions.ts';

const IFrameView = ({ block, className, ...props }: { block: PageBlock; className?: string }) => {
    const [blockData, setBlockData] = useState({} as PageBlockDataType);
    useEffect(() => {
        (async () => {
            if (block?.data?.[0])
                setBlockData({
                    ...(block.data[0] as PageBlock),
                    src: await addUrlSubstitutions(block.data[0].src!),
                } as PageBlockDataType);
        })();
    }, [block]);

    return (
        <iframe
            className={classNames(className)}
            allow=""
            height="100%"
            width="100%"
            frameBorder="0"
            src={blockData?.src}
            {...props}
            {...blockData?.elementAttributes}></iframe>
    );
};

export default IFrameView;
