import { Auth } from 'aws-amplify';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider as ApolloClientProvider,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { createAppSyncLink } from '@genflow/aws-appsync-apollo-link';
import environmentConfig from '../../environment-config';
import { ReactNode } from 'react';

const AppSyncConfig = environmentConfig.appsync;

const config = {
    url: AppSyncConfig.url,
    region: AppSyncConfig.region,
};

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        createAppSyncLink(Auth, config),
        new HttpLink({
            uri: config.url,
        }),
    ]),
});

const ApolloProvider = ({ children }: { children: ReactNode }) => (
    <ApolloClientProvider client={apolloClient}>{children}</ApolloClientProvider>
);

export default ApolloProvider;
