import { useQuery } from '@apollo/client';
import getUserAccessQuery from '../api/acl/getUserAccessQuery.graphql';
import { useCreatorConfig } from '../providers/ConfigProvider';
import { UserAccess } from '../types/user-access.ts';

const useUserAccess = (params?: { contentId?: string }) => {
    const { vendorUuid } = useCreatorConfig();
    const { data, loading } = useQuery(getUserAccessQuery, { variables: { vendorUuid }, skip: !vendorUuid });
    if (loading) return { isLoading: true } as UserAccess;

    const userAccess = data?.getUserAccess?.data;
    const entitlements = data?.getUserAccess?.entitlements;

    if (params?.contentId && userAccess) {
        const hasAccess = !!userAccess?.find(
            ({ key, contentId }: { key: string; contentId: string }) =>
                key === 'content.read' && contentId === params?.contentId?.toString(),
        );

        return { hasAccess } as UserAccess;
    }

    return { userAccess, entitlements } as UserAccess;
};

export default useUserAccess;
