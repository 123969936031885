import { useCreatorConfig } from '../../providers/ConfigProvider';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import PrimaryText from '../_base/PrimaryText';

const AssetItem = ({
    asset,
}: {
    asset: {
        assetType: {
            name: string;
        };
        title: string;
        url: string;
    };
}) => {
    const { theme } = useCreatorConfig();

    return (
        <a href={asset.url} target="_blank" key={asset.url} className="hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out">
            <div
                className="flex flex-row items-center justify-between p-2 rounded-xl"
                style={{
                    backgroundColor: theme?.colours?.secondaryColour,
                }}>
                <PrimaryText className="text-sm flex flex-row items-center gap-2">
                    {asset.title} <ArrowDownTrayIcon className="w-4 h-4" />
                </PrimaryText>
            </div>
        </a>
    );
};

export default AssetItem;
