import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/classNames.ts';
import { useTranslation } from 'react-i18next';
import { supportedLngs } from '../../init/i18n.ts';
import { useCreatorConfig } from '../../providers/ConfigProvider';

const LanguageSelector = () => {
    const { theme, languageSupport } = useCreatorConfig();
    const { i18n } = useTranslation();

    const changeLanguage = (isoCode: string) => i18n.changeLanguage(isoCode);

    const currentLanguage = supportedLngs.find(({ isoCode }) => isoCode === i18n.language);
    const languages = supportedLngs.filter(({isoCode}) => languageSupport?.indexOf(isoCode) > -1);

    return languages?.length > 1 && (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-3xl px-3 py-2 text-sm font-semibold shadow-sm border"
                    style={{
                        backgroundColor: theme?.colours?.secondaryColour,
                        borderColor: theme?.colours?.secondaryColour,
                    }}>
                    {currentLanguage?.label}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {languages.map(({ isoCode, label }) => (
                            <Menu.Item key={isoCode}>
                                {({ active }) => (
                                    <div
                                        onClick={() => changeLanguage(isoCode)}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm',
                                        )}>
                                        {label}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default LanguageSelector;
