import { CSSProperties } from 'react';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import PrimaryText from '../PrimaryText';

const Pill = ({
    label,
    size = 'xs',
    style,
}: {
    label: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    style?: CSSProperties;
}) => {
    const { theme } = useCreatorConfig();
    return (
        <PrimaryText
            className={`rounded-full font-bold px-3 py-1.5 text-${size.replace('md', 'base')}`}
            style={{
                backgroundColor: theme?.colours?.primaryTextColour,
                color:  theme?.colours?.backgroundColour,
                ...style,
            }}>
            {label}
        </PrimaryText>
    );
};

export default Pill;

