import { useQuery } from '@apollo/client';
import getVendorPagesQuery from '../api/pages/getVendorPagesQuery.graphql';
import { useCreatorConfig } from '../providers/ConfigProvider';
import { Page } from '../types/pages.ts';
import { useAuth } from '../providers/AuthProvider';

const usePages = () => {
    const { vendorUuid } = useCreatorConfig();
    const { cognitoUser } = useAuth();
    const { data, loading, refetch } = useQuery(getVendorPagesQuery, {
        variables: {
            vendorUuid,
        },
        skip: !vendorUuid || !cognitoUser?.attributes?.sub,
    });

    const pages = data?.getVendorPages?.data as Page[];
    const homePage = pages?.find(({ isHomePage }) => isHomePage);

    return { data: pages, isLoading: loading, homePage, refetch };
};

export default usePages;
