import './init/init.ts';
import './App.css';
import { ConfigProvider } from './providers/ConfigProvider';
import ApolloProvider from './providers/ApolloProvider';
import { AuthProvider } from './providers/AuthProvider';
import PlatformInitLoadingLayout from './components/PlatformInitLoadingLayout';
import AppRoutes from './AppRoutes.tsx';

function App() {
    return (
        <ConfigProvider>
            <ApolloProvider>
                <AuthProvider>
                    <PlatformInitLoadingLayout>
                        <AppRoutes />
                    </PlatformInitLoadingLayout>
                </AuthProvider>
            </ApolloProvider>
        </ConfigProvider>
    );
}

export default App;
