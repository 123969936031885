import React, { ComponentPropsWithRef } from 'react';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import classNames from '../../../utils/classNames.ts';
import { Oval } from 'react-loader-spinner';

const Button = ({
    children,
    className = '',
    size = 'base',
    leftIcon,
    rightIcon,
    isLoading,
    isPrimary,
    isDisabled,
    onClick,
    ...props
}: ComponentPropsWithRef<'button'> & {
    children: React.ReactNode;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
    isLoading?: boolean;
    isPrimary?: boolean;
    isDisabled?: boolean;
}) => {
    const { theme } = useCreatorConfig();
    let backgroundColor = isPrimary ? theme?.colours?.primaryColour : theme?.colours?.secondaryColour;
    if (isDisabled) backgroundColor = theme?.colours?.backgroundColour;

    return (
        <button
            className={classNames(
                `flex justify-center rounded-3xl px-3 py-1.5 text-${size.replace(
                    'md',
                    'base',
                )} font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`,
                className,
            )}
            style={{
                backgroundColor,
                color: theme?.colours?.textOnPrimaryColour,
            }}
            disabled={isDisabled}
            {...(!isLoading ? { onClick } : {})}
            {...props}>
            <div className={`flex flex-row items-center gap-2`}>
                {leftIcon}
                {isLoading && (
                    <Oval
                        height={12}
                        width={12}
                        color={theme?.colours?.textOnPrimaryColour}
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="rgba(0, 0, 0, 0.4)"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                )}
                {children}
                {rightIcon}
            </div>
        </button>
    );
};

export default Button;
