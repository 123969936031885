import { useCreatorConfig } from '../providers/ConfigProvider';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import getUserHabitGroupsQuery from '../api/habit-tracker/getUserHabitGroupsQuery.graphql';
import createUpdateHabitGroupMutation from '../api/habit-tracker/createUpdateHabitGroupMutation.graphql';
import trackHabitMutation from '../api/habit-tracker/trackHabitMutation.graphql';
import getUserHabitGroupsStatsQuery from '../api/habit-tracker/getUserHabitGroupsStatsQuery.graphql';
import { isEqual } from 'lodash';
import { Habit, HabitGroup, HabitsStatus, HabitGroupTrackHistory, HabitGroupStats } from '../types/habit-tracker.ts';
import { useAuth } from '../providers/AuthProvider';
import moment from 'moment-timezone';

export default () => {
    const { vendorUuid } = useCreatorConfig();
    const { cognitoUser } = useAuth();

    const [habitGroups, setHabitGroups] = useState([] as HabitGroup[]);
    const activeHabitGroup = habitGroups?.[0] as HabitGroup;
    const [trackHistoryToday, setTrackHistoryToday] = useState({} as HabitGroupTrackHistory);

    const userTimezone = cognitoUser?.attributes?.zoneinfo || 'Europe/London';
    const todayMoment = moment().tz(userTimezone);

    const { data, loading: isLoading, refetch } = useQuery(getUserHabitGroupsQuery, {
        variables: { vendorUuid },
        skip: !vendorUuid,
    });

    const { data: habitGroupStatsData, loading: isLoadingHabitGroupStats } = useQuery(getUserHabitGroupsStatsQuery, {
        variables: { vendorUuid },
        skip: !vendorUuid
    });
    const habitGroupStats = habitGroupStatsData?.getUserHabitGroupsStats?.data as HabitGroupStats;


    useEffect(() => {
        const activeGroups = (data?.getUserHabitGroups?.data as HabitGroup[])?.filter(
            ({ status }) => status !== 'disabled',
        );

        if (!isEqual(activeGroups, habitGroups)) setHabitGroups(activeGroups);
    }, [data]);

    const [createUpdateHabitGroupRequest, { loading: isUpdatingGroup }] = useMutation(createUpdateHabitGroupMutation, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: getUserHabitGroupsQuery,
                variables: { vendorUuid },
            },
        ],
    });

    const [trackHabitRequest, { loading: isTrackingHabit }] = useMutation(trackHabitMutation, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: getUserHabitGroupsQuery,
                variables: { vendorUuid },
            },
            {
                query: getUserHabitGroupsStatsQuery,
                variables: { vendorUuid },
            },
        ],
    });

    const createUpdateHabitGroup = ({
        habitGroupUuid = null,
        metadata,
    }: {
        habitGroupUuid?: string | null;
        metadata?: {
            name?: string;
            status?: HabitsStatus;
            duration?: number;
            durationUnits?: 'day';
            habits?: Habit[];
        };
    }) =>
        createUpdateHabitGroupRequest({
            variables: {
                vendorUuid,
                metadata: {
                    ...metadata,
                    habitGroupUuid,
                    status: metadata?.status || 'active',
                },
            },
            refetchQueries: [
                {
                    query: getUserHabitGroupsQuery,
                    variables: { vendorUuid },
                },
                {
                    query: getUserHabitGroupsStatsQuery,
                    variables: { vendorUuid },
                },
            ]
        });

    const trackHabit = ({
        habitUuid,
        metadata,
    }: {
        habitUuid: string;
        metadata?: {
            status: HabitsStatus;
        };
    }) =>
        trackHabitRequest({
            variables: {
                vendorUuid,
                habitUuid,
                metadata,
            },
        });

    const disableAllHabitGroups = async () => {
        // There should only ever be 1 group, this is just a safety check
        const activeGroups = habitGroups?.filter(({ status }) => status === 'active');

        await Promise.all(
            activeGroups.map(async (habitGroup) =>
                createUpdateHabitGroup({
                    habitGroupUuid: habitGroup.habitGroupUuid,
                    metadata: {
                        status: 'disabled',
                    },
                }),
            ),
        );
    };

    useEffect(() => {
        if (activeHabitGroup?.trackHistory?.[0]?.date) {
            const latestTrackHistoryDateMoment = moment(activeHabitGroup.trackHistory[0].date).tz(userTimezone);

            if (latestTrackHistoryDateMoment.isSame(moment().tz(userTimezone), 'day'))
                setTrackHistoryToday(activeHabitGroup.trackHistory[0]);
        }
    }, [activeHabitGroup, userTimezone]);

    return {
        habitGroups,
        refetch,
        isLoading,
        habitGroupStats,
        isLoadingHabitGroupStats,
        createUpdateHabitGroup,
        isUpdatingGroup,
        trackHabit,
        isTrackingHabit,
        disableAllHabitGroups,
        activeHabitGroup,
        trackHistoryToday,
        todayMoment,
        userTimezone
    };
};
