const VIDEO_CONSIDERED_COMPLETE_SECONDS = 20;
export default ({
    lastVideoPlayTimeSeconds,
    videoLengthSeconds,
}: {
    lastVideoPlayTimeSeconds: number;
    videoLengthSeconds: number;
}) => {
    if (!(videoLengthSeconds > 0) || !lastVideoPlayTimeSeconds) return false;
    const remainingSeconds = videoLengthSeconds - lastVideoPlayTimeSeconds;
    return remainingSeconds >= 0 && remainingSeconds <= VIDEO_CONSIDERED_COMPLETE_SECONDS;
};
