export default (colour?: string) => {
    if (!colour) return;
    // Variables for red, green, blue values
    let r: number, g: number, b: number;

    // Check the format of the color, HEX or RGB?
    if (colour.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        const rgbMatch = colour.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        if (!rgbMatch) throw new Error('Invalid RGB color format');

        r = parseInt(rgbMatch[1], 10);
        g = parseInt(rgbMatch[2], 10);
        b = parseInt(rgbMatch[3], 10);
    } else {
        const hexColor = colour.length < 5 ? colour.slice(1).replace(/./g, '$&$&') : colour.slice(1);
        const hexValue = parseInt(hexColor, 16);

        r = (hexValue >> 16) & 255;
        g = (hexValue >> 8) & 255;
        b = hexValue & 255;
    }

    return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) > 127.5 ? 'light' : 'dark';
};
