import getDomainName from '../../utils/getDomainName.ts';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import isColourLightOrDark from '../../utils/isColourLightOrDark.ts';

const POWERED_BY_URL = `https://genflow.com?utm_source=platform&utm_medium=organic&utm_campaign=${getDomainName()}`;
export default () => {
    const { theme, poweredBy } = useCreatorConfig();
    const darkOrLight = isColourLightOrDark(theme?.colours?.navigationColour2 || theme?.colours?.navigationColour) || 'light';

    if(!poweredBy?.isDisplayLogoEnabled) return;
    return (
        <a href={POWERED_BY_URL} target="blank">
            <img src={`/powered-by-logo-${
                {
                    dark: 'light',
                    light: 'dark',
                }[darkOrLight]
            }.svg`} className="my-10 w-20 mx-auto cursor-pointer" />
        </a>
    )
};
