import { apolloClient } from '../providers/ApolloProvider';
import getVendorCommunityJoinURLQuery from '../api/community/getVendorCommunityJoinURLQuery.graphql';
import { PageLinkCommunity } from '../types/pages.ts';

const getCommunityJoinLink = async ({
    vendorUuid,
    community,
}: {
    vendorUuid: string;
    community: PageLinkCommunity;
}) => {
    if (!community?.type || !community?.id) return '';

    const response = await apolloClient.query({
        query: getVendorCommunityJoinURLQuery,
        variables: {
            vendorUuid,
            communityType: community.type,
            communityId: community.id,
        },
    });

    return response?.data?.getVendorCommunityJoinURL?.data?.redirectUrl || '';
};

export default getCommunityJoinLink;
