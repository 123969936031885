import axios from 'axios';
import { useAuth } from '../providers/AuthProvider';
import { useCreatorConfig } from '../providers/ConfigProvider';
import {useState} from "react";

const usePublicImageUpload = () => {
    const { services } = useCreatorConfig();
    const { cognitoUser } = useAuth();
    const [uploadingPublicImage, setUploadingPublicImage] = useState(false);
    const getSignedUrl = (fileName: string) =>
        axios.get(`${services.publicImageUploadUrl}/${fileName}`, {
            headers: {
                Authorization: cognitoUser?.signInUserSession?.idToken?.jwtToken as string,
            },
        });

    const uploadPublicImage = async (file: File) => {
        setUploadingPublicImage(true);
        const response = await getSignedUrl(file.name);
        const { imageLink, uploadLink } = response?.data || {};
        if (!uploadLink) return;
        await axios.put(uploadLink, file, { headers: { 'Content-Type': file.type } });
        setUploadingPublicImage(false);
        return imageLink;
    };

    return {
        uploadPublicImage,
        uploadingPublicImage,
    };
};

export default usePublicImageUpload;
