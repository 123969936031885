import { Link, useParams } from 'react-router-dom';
import { CourseProductAsset, CourseProductSection } from '../../../types/course-product.ts';
import useContent from '../../../hooks/useContent.tsx';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import { orderBy } from 'lodash';
import NonDownloadableAssets from '../../../CONST/non-downloadable-assets.ts';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import classNames from '../../../utils/classNames.ts';
import PageLoader from '../../_pages/PageLoader';
import PrimaryText from '../../_base/PrimaryText';
import SecondaryText from '../../_base/SecondaryText';

const ResourcesModule = ({ className, ...props }: { className?: string }) => {
    const { theme } = useCreatorConfig();
    const { courseId, moduleId } = useParams();
    const { data, isLoading } = useContent({ type: 'course', productId: courseId as string });

    const module = (data?.sections as CourseProductSection[])?.find((section) => section.id === moduleId);
    const topBannerImage = data?.images?.[2]?.url;

    const assets = orderBy(
        module?.lessons?.reduce(
            (acc, { assets }) => [
                ...acc,
                ...(assets?.filter(({ asset_type }) => NonDownloadableAssets?.indexOf(asset_type?.name) === -1) || []),
            ],
            [] as CourseProductAsset[],
        ),
        ['order'],
        ['asc'],
    );

    // const moduleNumber = (data?.sections?.findIndex(({ id }) => id === module?.id) || 0) + 1;

    return (
        <div className={classNames(className)} {...props}>
            <PageLoader inline isLoading={!data || isLoading}>
                {topBannerImage && <img src={topBannerImage} className="w-full rounded-md mb-8" />}

                <div className="flex flex-col gap-4">
                    <div>
                        <PrimaryText block className="font-semibold text-3xl">
                            {data?.name}
                        </PrimaryText>
                        <SecondaryText block className="text-lg">
                            {module?.name}
                        </SecondaryText>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        {assets?.map((asset) => {
                            return (
                                <Link to={asset?.url as string} key={asset.id} target="_blank" className='hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out'>
                                    <div
                                        className="flex flex-row items-center p-4 rounded-md"
                                        style={{
                                            backgroundColor: theme?.colours?.secondaryColour,
                                            borderColor: theme?.colours?.secondaryColour,
                                        }}>
                                        <PrimaryText className="flex-1 text-lg font-semibold flex flex-row items-center justify-between">
                                            {asset.title}
                                            <ArrowDownTrayIcon className="w-6 h-6" />
                                        </PrimaryText>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </PageLoader>
        </div>
    );
};

export default ResourcesModule;
