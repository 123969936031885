const PRIMARY_COLOUR = '#000000';
const TEXT_ON_PRIMARY_COLOUR = '#FFFFFF';
const SECONDARY_COLOUR = '#F1F3F5';
const BACKGROUND_COLOUR = '#FFFFFF';
const PRIMARY_TEXT_COLOUR = '#000000';
const NAVIGATION_COLOUR = '#FFFFFF';
const CARD_BORDER_COLOUR = 'rgba(255,255,255,0.1)';

export const DEFAULT = {
    PRIMARY_COLOUR,
    TEXT_ON_PRIMARY_COLOUR,
    SECONDARY_COLOUR,
    BACKGROUND_COLOUR,
    PRIMARY_TEXT_COLOUR,
    NAVIGATION_COLOUR,
    CARD_BORDER_COLOUR,
};
