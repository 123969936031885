import { Link, useParams } from 'react-router-dom';
import { CourseProductSection } from '../../../types/course-product.ts';
import useContent from '../../../hooks/useContent.tsx';
import { orderBy } from 'lodash';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import NonDownloadableAssets from '../../../CONST/non-downloadable-assets.ts';
import classNames from '../../../utils/classNames.ts';
import PageLoader from '../../_pages/PageLoader';
import Pill from '../../_base/Pill';
import PrimaryText from '../../_base/PrimaryText';
import SecondaryText from '../../_base/SecondaryText';

const ResourcesModules = ({ className, ...props }: { className?: string }) => {
    const { theme } = useCreatorConfig();
    const { courseId } = useParams();
    const { data, isLoading } = useContent({ type: 'course', productId: courseId as string });

    const topBannerImage = data?.images?.[2]?.url;

    const modules = orderBy(
        data?.sections
            ?.map((module, index) => {
                const totalResources = module?.lessons?.reduce(
                    (acc, { assets }) =>
                        acc +
                        assets?.filter(({ asset_type }) => NonDownloadableAssets?.indexOf(asset_type?.name) === -1)
                            ?.length,
                    0,
                );
                return {
                    ...module,
                    moduleNumber: index + 1,
                    totalResources,
                };
            })
            ?.filter(({ totalResources }) => totalResources > 0),
        ['order'],
        ['asc'],
    ) as (CourseProductSection & {
        totalResources: number;
        moduleNumber: number;
    })[];

    return (
        <div className={classNames(className)} {...props}>
            <PageLoader inline isLoading={!data || isLoading}>
                {topBannerImage && <img src={topBannerImage} className="w-full rounded-md mb-8" />}
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col">
                        <PrimaryText className="font-semibold text-3xl">{data?.name}</PrimaryText>
                        <SecondaryText className="text-lg">Resources</SecondaryText>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        {modules?.map((module) => {
                            return (
                                <Link to={`/resources/${courseId}/modules/${module.id}`} key={module.id} className='hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out'>
                                    <div
                                        className="flex flex-row items-center p-4 rounded-md"
                                        style={{
                                            backgroundColor: theme?.colours?.secondaryColour,
                                            borderColor: theme?.colours?.secondaryColour,
                                        }}>
                                        <div className="flex-1 flex flex-col">
                                            <PrimaryText className="text-lg font-semibold">{module.name}</PrimaryText>
                                            {/*<SecondaryText className='text-base'>{`Module ${module.moduleNumber}`}</SecondaryText>*/}

                                            <div className="mt-2 flex items-center gap-x-4 text-xs">
                                                <Pill label={`${module?.totalResources} resources`} size="xs" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </PageLoader>
        </div>
    );
};

export default ResourcesModules;
