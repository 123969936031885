import { useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaCarouselType } from 'embla-carousel';
import { DotButton, useDotButton } from './DotButton';
import classNames from '../../../utils/classNames.ts';
import Autoplay from 'embla-carousel-autoplay';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { PageBlock } from '../../../types/pages.ts';
import { Link } from 'react-router-dom';
import SecondaryText from '../../_base/SecondaryText';

const MediaCarousel = ({
    title,
    block,
    className,
    ...props
}: {
    title?: string;
    block: PageBlock;
    className?: string;
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({}, [
        Autoplay({
            playOnInit: true,
            delay: 7000,
            stopOnInteraction: true,
        }),
        WheelGesturesPlugin(),
    ]);

    const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
        const autoplay = emblaApi?.plugins()?.autoplay;
        if (!autoplay) return;
    }, []);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi, onNavButtonClick);

    return (
        <div className={className} {...props}>
            {title && (
                <SecondaryText inlineBlock className="font-semibold mb-2 text-base">
                    {title}
                </SecondaryText>
            )}
            <div className="embla">
                <div className="embla__viewport" ref={emblaRef}>
                    <div className="embla__container">
                        {block?.data?.map(({ src, type, link }, i) => {
                            const SlideImage = () => (
                                <img
                                    src={src}
                                    alt={type}
                                    className="w-full h-full object-contain rounded-md overflow-hidden"
                                />
                            );

                            if (link)
                                return (
                                    <Link
                                        className="embla__slide"
                                        to={link?.target as string}
                                        key={i}
                                        target={link?.type === 'external' ? '_blank' : '_self'}>
                                        <SlideImage />
                                    </Link>
                                );

                            return (
                                <div className="embla__slide" key={i}>
                                    <SlideImage />
                                </div>
                            );
                        })}
                    </div>
                    {
                        block?.data?.length > 1 && (
                            <div className="embla__controls">
                                <div className="embla__dots">
                                    {scrollSnaps.map((_, index) => (
                                        <DotButton
                                            key={index}
                                            onClick={() => onDotButtonClick(index)}
                                            className={classNames(
                                                'embla__dot',
                                                index === selectedIndex ? ' embla__dot--selected' : '',
                                            )}
                                        />
                                    ))}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default MediaCarousel;
