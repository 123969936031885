import classNames from '../../../utils/classNames.ts';
import { PageBlock, PageLinkCommunity } from '../../../types/pages.ts';
import { useNavigate } from 'react-router-dom';
import SecondaryText from '../../_base/SecondaryText';
import addUrlSubstitutions from '../../../utils/addUrlSubstitutions.ts';
import getCommunityJoinLink from '../../../utils/getCommunityJoinLink.ts';
import { useCreatorConfig } from '../../../providers/ConfigProvider';

const ImageCard = ({ title, block, className }: { title?: string; block: PageBlock; className?: string }) => {
    const { vendorUuid } = useCreatorConfig();
    const navigate = useNavigate();

    const onClick = async () => {
        if (block?.link?.type === 'internal') navigate(block?.link?.target!);
        if (block?.link?.type === 'external') window.open(await addUrlSubstitutions(block?.link?.target!), '_blank');
        if (block?.link?.type === 'community')
            window.open(
                await getCommunityJoinLink({
                    vendorUuid,
                    community: block?.link?.community as PageLinkCommunity,
                }),
                '_blank',
            );
    };
    return (
        <div className={classNames('rounded-md overflow-hidden', className, block?.link && 'cursor-pointer')}>
            {title && (
                <SecondaryText inlineBlock className="font-semibold mb-2 text-base">
                    {title}
                </SecondaryText>
            )}
            <img
                src={block?.data?.[0]?.src}
                alt=""
                className={classNames('w-full object-cover rounded-md')}
                onClick={onClick}
            />
        </div>
    );
};

export default ImageCard;
