import { useCreatorConfig } from '../../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../providers/AuthProvider';
import Button from '../../components/_base/Button';

const NoAccessUpgrade = () => {
    const { t } = useTranslation();
    const { marketingSiteUrl, theme } = useCreatorConfig();
    const { cognitoUser, signOut } = useAuth();
    return (
        <div
            className="w-full min-h-screen rounded-xl flex flex-col items-center justify-center gap-4 text-center border"
            style={{
                borderColor: theme?.colours?.secondaryColour,
            }}>
            <div
                className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
                style={{
                    backgroundColor: theme?.colours?.secondaryColour,
                }}>
                <div>
                    <div
                        className="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
                        style={{
                            backgroundColor: theme?.colours?.textOnPrimaryColour,
                            borderColor: theme?.colours?.primaryColour,
                            borderWidth: 1,
                        }}>
                        <LockClosedIcon className="w-6 h-6" style={{ color: theme?.colours?.primaryColour }} />
                    </div>
                    <div
                        className="mt-3 text-center sm:mt-5"
                        style={{
                            color: theme?.colours?.primaryTextColour,
                        }}>
                        <div className="text-base font-semibold leading-6">{
                            marketingSiteUrl ? 'Unlock Access' : 'No Access'
                        }</div>
                        <div className="mt-2">
                            <p className="text-sm">
                                {marketingSiteUrl
                                    ? 'To unlock this content please purchase access.'
                                    : 'You do not have access to use this platform.'}
                            </p>
                        </div>
                    </div>
                </div>
                {marketingSiteUrl && (
                    <div className="mt-5 sm:mt-6 flex justify-center">
                        <a href={marketingSiteUrl}>
                            <Button isPrimary>{t('Purchase Access')}</Button>
                        </a>
                    </div>
                )}
            </div>

            {cognitoUser && (
                <div className="text-center mt-10 text-sm opacity-40">
                    <div>{cognitoUser?.attributes?.email}</div>
                    <div onClick={signOut} className="mt-2 block underline cursor-pointer">
                        Sign Out
                    </div>
                </div>
            )}
        </div>
    );
};

export default NoAccessUpgrade;
