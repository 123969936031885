import PageLoader from '../../_pages/PageLoader';
import classNames from '../../../utils/classNames.ts';
import useHabitTracker from '../../../hooks/useHabitTracker.ts';
import { chunk, orderBy, range } from 'lodash';
import HabitCard from '../../HabitCard';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import HabitTrackerStartModal from '../../HabitTrackerStartModal';
import { useEffect, useState } from 'react';
import HabitTrackerChallenges from '../../HabitTrackerChallenges';
import HabitTrackerMilestones from '../../HabitTrackerMilestones';
import HabitTrackerCompleteModal from '../../HabitTrackerCompleteModal';
import { PageBlock } from '../../../types/pages.ts';
import PrimaryText from '../../_base/PrimaryText';
import SecondaryText from '../../_base/SecondaryText';
import HabitTrackerFailedModal from '../../HabitTrackerFailedModal';

const DAYS = [21, 30, 45, 60, 75, 90];
const HabitTrackerView = ({ className, block, ...props }: { className?: string; block: PageBlock }) => {
    const {
        isLoading,
        activeHabitGroup,
        habitGroupStats,
        trackHistoryToday,
        todayMoment,
        disableAllHabitGroups,
        userTimezone,
    } = useHabitTracker();
    const completedStats = habitGroupStats?.byCompletionStatus?.find(
        ({ completionStatus }) => completionStatus === 'FINISHED',
    );

    const [isHabitTrackerStartModalOpen, setIsHabitTrackerStartModalOpen] = useState(false as number | false);
    const [isHabitTrackerCompleteModalOpen, setIsHabitTrackerCompleteModalOpen] = useState(false);
    const [isHabitTrackerFailedModalOpen, setIsHabitTrackerFailedModalOpen] = useState(false);

    useEffect(() => {
        if (activeHabitGroup?.completionStatus === 'FINISHED') setIsHabitTrackerCompleteModalOpen(true);
        if (activeHabitGroup?.completionStatus === 'FAILED') setIsHabitTrackerFailedModalOpen(true);
    }, [activeHabitGroup]);


    const fixedHabitNames = block?.data?.[0]?.fixedHabits?.map(({ habitName }) => habitName) || [];
    const activeHabitGroupHabits =
        fixedHabitNames?.length > 0 &&
        orderBy([...(activeHabitGroup?.habits || [])], 'createdTimestamp')?.sort(({ habitName: habitNameA }, { habitName: habitNameB }) => fixedHabitNames?.indexOf(habitNameA) - fixedHabitNames?.indexOf(habitNameB));

    return (
        <div className={classNames(className)} {...props}>
            <PageLoader inline isLoading={isLoading}>
                {activeHabitGroup && (
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-row items-center text-sm gap-1">
                            <SecondaryText>Your day will end at midnight in the</SecondaryText>
                            <SecondaryText className="flex flex-row items-center gap-1">
                                <PrimaryText className="font-bold">{userTimezone}</PrimaryText>
                                <SecondaryText>timezone.</SecondaryText>
                            </SecondaryText>
                            <SecondaryText>To update this, head over to</SecondaryText>
                            <Link className="underline cursor-pointer" to="/settings">
                                <PrimaryText>Settings</PrimaryText>
                            </Link>
                        </div>
                        <div className="flex flex-col lg:flex-row items-stretch justify-center gap-4">
                            <div className="flex flex-col items-stretch justify-center gap-4">
                                <div className="flex flex-col justify-center items-center border-2 border-[#FFFFFF1A] rounded-md p-4">
                                    <PrimaryText className="text-8xl font-bold">
                                        {activeHabitGroup?.lastStreak}
                                    </PrimaryText>
                                    <PrimaryText className="text-2xl font-bold">Day Streak</PrimaryText>
                                    <SecondaryText className="text-base">{`${
                                        activeHabitGroup?.duration - activeHabitGroup?.lastStreak
                                    } Days Left`}</SecondaryText>
                                    <SecondaryText
                                        inlineBlock
                                        onClick={disableAllHabitGroups}
                                        className="mt-4 cursor-pointer underline text-xs">
                                        Restart
                                    </SecondaryText>
                                </div>
                                <div className="flex-1 flex flex-col items-center justify-center gap-4 rounded-md p-4 w-full sm:w-auto border-2 border-[#FFFFFF1A]">
                                    {chunk(range(1, (activeHabitGroup?.duration || 0) + 1), 7).map(
                                        (weekDays, index) => (
                                            <div className="flex gap-x-3 gap-y-2 2xl:gap-x-6 2xl:gap-y-4" key={index}>
                                                {weekDays.map((day, index) => (
                                                    <div className="relative" key={index}>
                                                        <div
                                                            className={`w-8 h-8 2xl:w-8 2xl:h-8 rounded-full border-2 flex items-center justify-center ${
                                                                day <= activeHabitGroup?.lastStreak
                                                                    ? 'border-[#CBD5E1]'
                                                                    : 'border-[#42464E]'
                                                            }`}>
                                                            <PrimaryText
                                                                className={`text-sm ${
                                                                    day <= activeHabitGroup?.lastStreak
                                                                        ? 'text-[#CBD5E1]'
                                                                        : 'text-[#42464E]'
                                                                } font-extrabold`}>
                                                                {day}
                                                            </PrimaryText>
                                                        </div>
                                                        {day === activeHabitGroup?.lastStreak + 1 &&
                                                            activeHabitGroup?.lastStreak !==
                                                                activeHabitGroup?.duration && (
                                                                <div className="bg-gray-100 h-1 w-2 absolute -bottom-2 left-2/4 -translate-x-2/4 rounded-full"></div>
                                                            )}
                                                    </div>
                                                ))}
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                            <div className="flex-1 border-2 border-[#FFFFFF1A] rounded-md p-4">
                                <div className="flex items-center justify-between">
                                    <PrimaryText className="text-2xl font-semibold">
                                        {todayMoment?.format('MMMM Do')}
                                    </PrimaryText>
                                    {trackHistoryToday?.habitsTracked !== activeHabitGroup?.habits?.length && (
                                        <Countdown
                                            date={todayMoment.add(1, 'day').startOf('day').valueOf()}
                                            renderer={({ hours, minutes, seconds }) => (
                                                <PrimaryText>
                                                    {[
                                                        hours && `${hours}hrs`,
                                                        minutes && `${minutes}mins`,
                                                        seconds && `${seconds}s`,
                                                        'left',
                                                    ]
                                                        .filter(Boolean)
                                                        .join(' ')}
                                                </PrimaryText>
                                            )}
                                            onComplete={() => window.location.reload()}
                                        />
                                    )}
                                </div>
                                <div className="mt-4 flex flex-col gap-4">
                                    {(activeHabitGroupHabits || activeHabitGroup?.habits)?.map((habit) => (
                                        <HabitCard
                                            habit={habit}
                                            key={habit?.habitUuid}
                                            isCompleted={
                                                trackHistoryToday?.habitUuids
                                                    ? trackHistoryToday?.habitUuids?.indexOf(
                                                          habit?.habitUuid as string,
                                                      ) > -1
                                                    : false
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row items-stretch gap-4 ">
                            <HabitTrackerChallenges />
                            <HabitTrackerMilestones />
                        </div>
                    </div>
                )}
                {!activeHabitGroup && (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-2 w-full h-full">
                        {DAYS.map((day) => {
                            const completedHabitGroups =
                                completedStats?.byDuration?.find(({ duration }) => duration === day)?.total || 0;
                            return (
                                <div
                                    key={day}
                                    className="border-2 border-[#FFFFFF1A] rounded-md p-10 flex flex-col justify-center items-center gap-4 cursor-pointer hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out"
                                    onClick={() => setIsHabitTrackerStartModalOpen(day)}>
                                    <div className="flex flex-col justify-center items-center border-2 border-[#FFFFFF1A] rounded-full w-52 h-52">
                                        <PrimaryText className="text-8xl leading-[.75]">{day}</PrimaryText>
                                        <PrimaryText className="text-2xl font-semibold">Days</PrimaryText>
                                    </div>
                                    {completedHabitGroups > 0 && (
                                        <SecondaryText>{`${completedHabitGroups} completed`}</SecondaryText>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
                {!!isHabitTrackerStartModalOpen && (
                    <HabitTrackerStartModal
                        open={!!isHabitTrackerStartModalOpen}
                        onClose={() => setIsHabitTrackerStartModalOpen(false)}
                        duration={(isHabitTrackerStartModalOpen as number) ?? 0}
                        customHabitsSize={block?.data?.[0]?.customHabitsSize}
                        fixedHabits={block?.data?.[0]?.fixedHabits}
                    />
                )}
                {!!isHabitTrackerCompleteModalOpen && (
                    <HabitTrackerCompleteModal
                        open={!!isHabitTrackerCompleteModalOpen}
                        onClose={() => setIsHabitTrackerCompleteModalOpen(false)}
                    />
                )}

                {!!isHabitTrackerFailedModalOpen && (
                    <HabitTrackerFailedModal
                        open={!!isHabitTrackerFailedModalOpen}
                        onClose={() => setIsHabitTrackerFailedModalOpen(false)}
                    />
                )}
            </PageLoader>
        </div>
    );
};

export default HabitTrackerView;
