import { createContext, ReactNode, useContext } from 'react';
import environmentConfig from '../../environment-config';
import { CreatorConfigType } from '../../types/creator-config.ts';
import { useQuery } from '@apollo/client';
import getDomainVendorConfigQuery from '../../api/vendor/getDomainVendorConfigQuery.graphql';
import useDomainName from '../../hooks/useDomainName.tsx';

// Provider definition
const defaultConfig = environmentConfig;
const ConfigContext = createContext(defaultConfig);
const { Provider, Consumer: ConfigConsumer } = ConfigContext;
const ConfigProvider = ({ children }: { children: ReactNode }) => <Provider value={defaultConfig}>{children}</Provider>;

const addVendorConfigDefaults = (creatorConfig: Omit<CreatorConfigType, 'appsync' | 'cognito' | 'content'>) => {
    return {
        ...creatorConfig,
        theme: {
            ...creatorConfig?.theme,
            logos: {
                ...creatorConfig?.theme?.logos,
                // logoDark: creatorConfig?.theme?.logos?.logoDark ?? '/logo-icon.png',
                // logoLight: creatorConfig?.theme?.logos?.logoLight ?? '/logo-icon.png',
                // logoIconDark: creatorConfig?.theme?.logos?.logoIconDark ?? '/logo-icon.png',
                // logoIconLight: creatorConfig?.theme?.logos?.logoIconLight ?? '/logo-icon.png',
            },
            colours: {
                ...creatorConfig?.theme?.colours,
                // primaryColour: creatorConfig?.theme?.colours?.primaryColour || DEFAULT.PRIMARY_COLOUR,
                // textOnPrimaryColour: creatorConfig?.theme?.colours?.textOnPrimaryColour || DEFAULT.TEXT_ON_PRIMARY_COLOUR,
                // secondaryColour: creatorConfig?.theme?.colours?.secondaryColour || DEFAULT.SECONDARY_COLOUR,
            },
        },
    };
};

// Quick access hook
const useCreatorConfig = () => {
    const domainName = useDomainName();
    const { data } = useQuery(getDomainVendorConfigQuery, {
        variables: { domainName },
        skip: !domainName,
    });

    const vendorConfig = addVendorConfigDefaults(data?.getDomainVendorConfig?.data);
    const environmentConfig = useContext(ConfigContext);
    const config = { ...environmentConfig, ...vendorConfig };

    return config as CreatorConfigType;
};

export { ConfigProvider, ConfigConsumer, useCreatorConfig };
