import { HandThumbDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import useHabitTracker from '../../hooks/useHabitTracker.ts';
import Button from '../_base/Button';
import PrimaryText from '../_base/PrimaryText';

const HabitTrackerFailedModal = ({ open, onClose }: { open?: boolean; onClose: () => void }) => {
    const { theme } = useCreatorConfig();
    const { activeHabitGroup, disableAllHabitGroups, isLoading } = useHabitTracker();

    const [isModalOpen, setIsModalOpen] = useState(open);

    useEffect(() => {
        setIsModalOpen(open);
    }, [open]);

    useEffect(() => {
        if (!isModalOpen) onClose();
    }, [isModalOpen]);

    const onClickStartNew = async () => disableAllHabitGroups().then(onClose);

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex h-screen w-full items-center justify-center p-4 sm:p-0 sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full lg:max-w-lg sm:p-6"
                                style={
                                    theme?.colours?.backgroundColour2
                                        ? {
                                              background: `linear-gradient(${theme?.colours?.backgroundColour}, ${theme?.colours?.backgroundColour2})`,
                                          }
                                        : { background: theme?.colours?.backgroundColour }
                                }>
                                {/*<div className="absolute right-0 top-0 pr-4 pt-4">*/}
                                {/*    <button*/}
                                {/*        type="button"*/}
                                {/*        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
                                {/*        onClick={() => setIsModalOpen(false)}>*/}
                                {/*        <span className="sr-only">Close</span>*/}
                                {/*        <XMarkIcon className="h-6 w-6" aria-hidden="true" />*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div className="mt-6 flex flex-col gap-4 justify-center items-center text-center">
                                    <div className="border-2 border-[#FFFFFF1A] rounded-md p-4">
                                        <HandThumbDownIcon
                                            className={'h-12 w-12'}
                                            style={{
                                                color: theme?.colours?.primaryColour,
                                            }}
                                        />
                                    </div>
                                    <Dialog.Title className="font-semibold leading-6 text-xl">
                                        <PrimaryText>{`You failed the ${activeHabitGroup?.duration} ${activeHabitGroup?.durationUnits} challenge at a ${activeHabitGroup?.lastStreak} ${activeHabitGroup?.durationUnits} streak!`}</PrimaryText>
                                    </Dialog.Title>
                                    <PrimaryText>
                                        {`You were not able to complete your journey. We hope you achieved as much as you could. Let's try again?`}
                                    </PrimaryText>
                                    <Button
                                        isPrimary
                                        isLoading={isLoading}
                                        onClick={onClickStartNew}>{`Start New`}</Button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default HabitTrackerFailedModal;
