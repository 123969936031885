import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import { range } from 'lodash';
import useHabitTracker from '../../hooks/useHabitTracker.ts';
import { HabitsStatus } from '../../types/habit-tracker.ts';
import Button from '../_base/Button';
import PrimaryText from '../_base/PrimaryText';
import SecondaryText from '../_base/SecondaryText';

const HabitTrackerStartModal = ({
    open,
    onClose,
    duration,
    fixedHabits,
    customHabitsSize = 3,
}: {
    open?: boolean;
    onClose: () => void;
    duration: number;
    fixedHabits?: {
        habitName: string;
        imageUrl?: string;
    }[];
    customHabitsSize?: number;
}) => {
    const { theme } = useCreatorConfig();
    const { createUpdateHabitGroup, isUpdatingGroup } = useHabitTracker();

    const [isModalOpen, setIsModalOpen] = useState(open);
    const [customHabits, setCustomHabits] = useState(range(customHabitsSize).map((_) => ({ habitName: '' })));
    const updateCustomHabits = (index: number, habitName: string) =>
        setCustomHabits(
            customHabits.map((customHabit, i) => (i === index ? { ...customHabit, habitName } : customHabit)),
        );

    useEffect(() => {
        setIsModalOpen(open);
    }, [open]);

    useEffect(() => {
        if (!isModalOpen) onClose();
    }, [isModalOpen]);

    const onClickCreateHabitGroup = () =>
        createUpdateHabitGroup({
            metadata: {
                name: `${duration} days habit group`,
                duration,
                durationUnits: 'day',
                habits: [
                    ...(fixedHabits || [])
                        ?.filter(({ habitName }) => habitName?.trim())
                        ?.map(({ habitName, imageUrl }) => ({
                            habitName,
                            imageUrl,
                            frequency: 'daily',
                            perDay: 1,
                            status: 'active' as HabitsStatus,
                        })),
                    ...(customHabits || [])
                        ?.filter(({ habitName }) => habitName?.trim())
                        ?.map(({ habitName }) => ({
                            habitName,
                            frequency: 'daily',
                            perDay: 1,
                            status: 'active' as HabitsStatus,
                        })),
                ],
            },
        }).then(onClose);

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex h-screen w-full items-center justify-center p-4 sm:p-0 sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full lg:max-w-2xl sm:p-6"
                                style={{ background: theme?.colours?.backgroundColour }}>
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setIsModalOpen(false)}>
                                        <PrimaryText>
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </PrimaryText>
                                    </button>
                                </div>
                                <div className="mt-6 flex flex-col gap-4">
                                    <div className="flex flex-col lg:flex-row gap-4 flex-1 text-base">
                                        {fixedHabits && fixedHabits?.length > 0 && (
                                            <div className="border-2 border-[#FFFFFF1A] rounded-md p-4 flex flex-col gap-4 flex-1">
                                                <SecondaryText className="font-semibold text-lg">
                                                    Non Negotiables
                                                </SecondaryText>
                                                {fixedHabits?.map(({ habitName, imageUrl }) => (
                                                    <div className="border-2 border-[#FFFFFF1A] rounded-md p-2" style={{}}>
                                                        {imageUrl && <img src={imageUrl} className="w-4 h-4" />}
                                                        <PrimaryText>{habitName}</PrimaryText>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className="border-2 border-[#FFFFFF1A] rounded-md p-4 flex flex-col gap-4 flex-1">
                                            <SecondaryText
                                                className="font-semibold text-lg">
                                                Your Custom Habits
                                            </SecondaryText>
                                            {range(customHabitsSize).map((i) => (
                                                <div>
                                                    <input
                                                        key={i}
                                                        id="email-address"
                                                        name="email"
                                                        type="email"
                                                        autoComplete="email"
                                                        required
                                                        className="w-full border-2 border-[#FFFFFF1A] rounded-md p-2 text-base"
                                                        style={{
                                                            backgroundColor: theme?.colours?.backgroundColour,
                                                        }}
                                                        placeholder="Add a custom habit..."
                                                        value={customHabits?.[i]?.habitName ?? ''}
                                                        onChange={(e) => updateCustomHabits(i, e.target.value)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex justify-end pr-4">
                                        <Button
                                            isPrimary
                                            isLoading={isUpdatingGroup}
                                            onClick={onClickCreateHabitGroup}>{`Start for ${duration} days`}</Button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default HabitTrackerStartModal;
