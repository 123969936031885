import { Oval } from 'react-loader-spinner';
import { useCreatorConfig } from '../../../providers/ConfigProvider';

const Loader = ({ height = 60, width = 60 }: { height?: number; width?: number }) => {
    const { theme } = useCreatorConfig();

    if (theme?.indicators?.loopUrl)
        return <img className="object-contain" style={{ width, height }} src={theme.indicators.loopUrl} />;

    if (!theme?.indicators?.loopUrl && !!theme?.colours?.primaryColour)
        return (
            <Oval
                height={height}
                width={width}
                color={theme?.colours?.primaryColour}
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="rgba(0, 0, 0, 0.4)"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        );

    return null;
};

export default Loader;
