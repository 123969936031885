import { useCreatorConfig } from '../../../providers/ConfigProvider';
import useUserAccess from '../../../hooks/useUserAccess.tsx';
import { Link } from 'react-router-dom';
import classNames from '../../../utils/classNames.ts';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { orderBy } from 'lodash';
import PageLoader from '../../_pages/PageLoader';
import PrimaryText from '../../_base/PrimaryText';
import PageBlock from '../../_pages/PageBlock';

const ResourcesList = ({ className, block, ...props }: { className?: string; block: PageBlock }) => {
    const { content, theme } = useCreatorConfig();
    const filters = block?.data?.[0]?.filters;
    const { userAccess, isLoading: isLoadingAccess } = useUserAccess();
    const resourceItems = orderBy(
        content
            ?.filter(
                ({ type, tags, totalResources }) =>
                    type === 'Course' &&
                    totalResources > 0 &&
                    ((filters?.tags?.length || 0) > 0 ? filters?.tags?.some((tag) => tags.includes(tag)) : true),
            )
            ?.map((contentItem) => ({
                ...contentItem,
                isLocked: !userAccess?.find(({ contentId }) => contentId === contentItem?.id),
            })),
        'isLocked',
        ['asc'],
    );

    return (
        <div className={className} {...props}>
            <PageLoader inline isLoading={isLoadingAccess}>
                <div className={classNames('grid grid-cols-1 lg:grid-cols-2 gap-4')}>
                    {resourceItems?.length > 0 &&
                        resourceItems?.map((contentItem) => {
                            const imageSrc = contentItem?.images?.[1]?.url;
                            const CoverImage = ({
                                className,
                                isLocked,
                            }: {
                                className?: string;
                                isLocked?: boolean;
                            }) => (
                                <div
                                    key={contentItem.id}
                                    className={classNames(
                                        'relative rounded-xl overflow-hidden',
                                        !imageSrc && 'w-full h-52 flex items-center justify-center px-4',
                                        className,
                                    )}
                                    style={
                                        imageSrc
                                            ? {}
                                            : {
                                                  backgroundColor: theme?.colours?.secondaryColour,
                                              }
                                    }>
                                    {imageSrc ? (
                                        <img src={imageSrc} alt="" className="w-full object-cover" />
                                    ) : (
                                        <PrimaryText className="text-lg lg:text-xl font-semibold text-center">
                                            {contentItem?.name}
                                        </PrimaryText>
                                    )}

                                    {isLocked && (
                                        <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                                            <LockClosedIcon className="w-16 h-16 text-white" />
                                        </div>
                                    )}
                                </div>
                            );
                            if (contentItem?.isLocked)
                                return <CoverImage className="opacity-30" isLocked key={`cover-${contentItem.id}`} />;

                            return (
                                <Link
                                    to={`/resources/${contentItem.id}`}
                                    key={contentItem.id}
                                    className="hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out">
                                    <CoverImage />
                                </Link>
                            );
                        })}
                </div>
            </PageLoader>
        </div>
    );
};

export default ResourcesList;
