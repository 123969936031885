import { ComponentPropsWithRef } from 'react';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import classNames from '../../../utils/classNames.ts';

export const PrimaryText = ({
    isSelected,
    inlineBlock,
    isOnThumbnail,
    block,
    className,
    style,
    children,
    ref,
    ...props
}: ComponentPropsWithRef<'span'> & {
    isSelected?: boolean;
    inlineBlock?: boolean;
    isOnThumbnail?: boolean;
    block?: boolean;
}) => {
    const { theme } = useCreatorConfig();
    const primaryTextColour = isOnThumbnail
        ? theme?.colours?.primaryTextOnThumbnailColour || theme?.colours?.primaryTextColour
        : theme?.colours?.primaryTextColour;

    return (
        <span
            style={{
                color: isSelected ? theme?.colours?.primaryColour : primaryTextColour,
                ...style,
            }}
            className={classNames(className, inlineBlock && 'inline-block', block && 'block')}
            {...props}
            ref={ref}>
            {children}
        </span>
    );
};

export default PrimaryText;
