import { useQuery } from '@apollo/client';
import getDomainVendorConfigQuery from '../../api/vendor/getDomainVendorConfigQuery.graphql';
import { ReactNode } from 'react';
import useDomainName from '../../hooks/useDomainName.tsx';
import usePages from '../../hooks/usePages.tsx';
import PageLoader from '../_pages/PageLoader';

const PlatformInitLoadingLayout = ({ children }: { children: ReactNode }) => {
    const domainName = useDomainName();
    const { loading } = useQuery(getDomainVendorConfigQuery, {
        variables: { domainName },
        skip: !domainName,
    });
    const { isLoading } = usePages();

    return <PageLoader isLoading={loading || isLoading}>{children}</PageLoader>;
};

export default PlatformInitLoadingLayout;
