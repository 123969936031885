import env from '../../../env.ts';
import { CreatorConfigType } from '../../types/creator-config.ts';
// @ts-ignore
import logSymbols from 'log-symbols';
import { DEFAULT } from '../../_defaults/theme.ts';
import getDomainName from '../../utils/getDomainName.ts';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';

const formattedMessage = (message: string) => `[prebuild] ${message}`;
const formattedConsoleLog = (...log: string[]) => console.log(formattedMessage(log.join(' ')));
const vendorConfigApi = {
    development: 'https://upp9jsw740.execute-api.eu-west-1.amazonaws.com/dev/vendors/domain/{domainName}/config',
    production: 'https://ns3utr3gcj.execute-api.eu-west-1.amazonaws.com/prod/vendors/domain/{domainName}/config',
}[env];

// Tasks to run in order for prebuild script.
const tasks = ({ vendorConfig }: { vendorConfig: CreatorConfigType }) =>
    [
        {
            name: 'Adding BugSnag vendor metadata',
            condition: !!vendorConfig?.vendorUuid,
            task: async () => {
                Bugsnag.addMetadata('vendor', {
                    vendorUuid: vendorConfig.vendorUuid,
                    vendorName: vendorConfig.brandName,
                });
            },
        },
        {
            name: 'Update HTML title element',
            condition: !!vendorConfig?.brandName,
            task: async () => (document.title = vendorConfig.brandName),
        },
        {
            name: 'Update favicon',
            condition: !!vendorConfig?.theme?.logos?.logoIconDark,
            task: async () => {
                const linkElement = document.createElement('link');
                linkElement.href = vendorConfig.theme.logos.logoIconDark;
                linkElement.rel = 'icon';
                document.head.appendChild(linkElement);
            },
        },
        {
            name: 'Update global theming i.e text & background colour',
            task: async () =>
                document.body.setAttribute(
                    'style',
                    `${
                        vendorConfig?.theme?.colours?.backgroundColour2
                            ? `background:${vendorConfig?.theme?.colours?.backgroundColour};background-image:linear-gradient(${vendorConfig?.theme?.colours?.backgroundColour}, ${vendorConfig?.theme?.colours?.backgroundColour2});background-repeat:no-repeat; background-attachment: fixed`
                            : `background:${
                                  vendorConfig?.theme?.colours?.backgroundColour || DEFAULT.BACKGROUND_COLOUR
                              }`
                    };color:${vendorConfig?.theme?.colours?.primaryTextColour || DEFAULT.PRIMARY_TEXT_COLOUR}`,
                ),
        },
        {
            name: 'Update typography',
            condition: !!vendorConfig?.theme?.typography?.fontUrl && !!vendorConfig?.theme?.typography?.fontFamilyName,
            task: async () => {
                const linkElement = document.createElement('link');
                linkElement.rel = 'stylesheet';
                linkElement.href = vendorConfig.theme.typography.fontUrl;
                document.head.appendChild(linkElement);
                const styleElement = document.createElement('style');
                styleElement.textContent = `
  :root{
    --vendor-font: "${vendorConfig.theme.typography.fontFamilyName}";
  }`;
                console.log(styleElement);
                document.head.appendChild(styleElement);
            },
        },
        {
            name: 'Initialise Meta pixel',
            condition: env === 'production' && !!vendorConfig?.thirdPartyIntegrations?.meta?.pixelId,
            task: async () => {
                if (window?.fbq) {
                    fbq('init', vendorConfig.thirdPartyIntegrations.meta.pixelId);
                    fbq('track', 'PageView');
                }
            },
        },
        {
            name: 'Add Google Analytics tag script',
            condition: env === 'production' && !!vendorConfig?.thirdPartyIntegrations?.googleAnalytics?.trackingId,
            task: async () => {
                const scriptElement = document.createElement('script');
                scriptElement.async = true;
                scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${vendorConfig.thirdPartyIntegrations.googleAnalytics.trackingId}`;
                document.head.appendChild(scriptElement);
            },
        },
        {
            name: 'Initialise Google Analytics tag',
            condition: env === 'production' && !!vendorConfig?.thirdPartyIntegrations?.googleAnalytics?.trackingId,
            task: async () => {
                window.dataLayer = window.dataLayer || [];
                window.gtag = function gtag() {
                    window.dataLayer.push(arguments);
                };
                gtag('js', new Date());
                gtag('config', vendorConfig.thirdPartyIntegrations.googleAnalytics.trackingId);
            },
        },
        {
            name: 'Add Google Tag Manager scripts',
            condition: env === 'production' && !!vendorConfig?.thirdPartyIntegrations?.googleAnalytics?.tagManagerId,
            task: async () => {
                const scriptElement = document.createElement('script');
                scriptElement.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${vendorConfig.thirdPartyIntegrations.googleAnalytics.tagManagerId}');`;
                document.head.appendChild(scriptElement);

                const noScriptElement = document.createElement('noscript');
                const iFrameElement = document.createElement('iframe');
                iFrameElement.src = `https://www.googletagmanager.com/ns.html?id=${vendorConfig.thirdPartyIntegrations.googleAnalytics.tagManagerId}`;
                iFrameElement.height = '0';
                iFrameElement.width = '0';
                iFrameElement.style.display = 'none';
                iFrameElement.style.visibility = 'hidden';
                noScriptElement.appendChild(iFrameElement);

                document.body.appendChild(noScriptElement);
            },
        },
        {
            name: 'Inject Custom Code',
            condition:
                !!vendorConfig?.theme?.customCode?.headCustomCode || !!vendorConfig?.theme?.customCode?.bodyCustomCode,
            task: async () => {
                const cloneAttributes = (target: HTMLElement, source: HTMLElement) =>
                    [...source.attributes].forEach((attr) => {
                        target.setAttribute(attr.nodeName, attr.nodeValue!);
                    });
                const injectCustomCode = (customCode: string, injectLocation: 'head' | 'body') => {
                    const dom = new DOMParser().parseFromString(customCode, 'text/html');
                    const scriptElements = dom.documentElement.querySelectorAll('script');
                    const styleElements = dom.documentElement.querySelectorAll('style');

                    if (scriptElements?.length > 0)
                        for (const scriptElement of scriptElements) {
                            const scriptDomElement = document.createElement('script');
                            scriptDomElement.textContent = scriptElement.textContent;
                            cloneAttributes(scriptDomElement, scriptElement);

                            if (injectLocation === 'head') document.head.appendChild(scriptDomElement);
                            if (injectLocation === 'body') document.body.appendChild(scriptDomElement);
                        }

                    if (styleElements?.length > 0)
                        for (const styleElement of styleElements) {
                            const styleDomElement = document.createElement('style');
                            styleDomElement.textContent = styleElement.textContent;
                            cloneAttributes(styleDomElement, styleElement);

                            if (injectLocation === 'head') document.head.appendChild(styleDomElement);
                            if (injectLocation === 'body') document.body.appendChild(styleDomElement);
                        }
                };

                if (vendorConfig?.theme?.customCode?.headCustomCode)
                    injectCustomCode(vendorConfig.theme.customCode.headCustomCode, 'head');
                if (vendorConfig?.theme?.customCode?.bodyCustomCode)
                    injectCustomCode(vendorConfig.theme.customCode.bodyCustomCode, 'body');
            },
        },
    ] as {
        name: string;
        condition: boolean;
        task: () => Promise<void>;
    }[];

(async () => {
    formattedConsoleLog(logSymbols.info, 'Starting prebuild script...');
    formattedConsoleLog(logSymbols.info, 'env:', env);
    const locationOrigin = getDomainName();
    formattedConsoleLog(logSymbols.info, 'domainName:', locationOrigin);
    if (!locationOrigin) throw new Error(formattedMessage('No vendorUuid found.'));

    /*
     * Get vendorConfig to before running tasks.
     * */
    const vendorConfigApiUrl = vendorConfigApi.replace('{domainName}', locationOrigin);
    const {
        data: { data: vendorConfig },
    } = await axios.get(vendorConfigApiUrl);
    formattedConsoleLog(logSymbols.info, 'vendorConfig:', JSON.stringify(vendorConfig));

    /*
     * Run all tasks
     * */
    for (const { name, task, condition } of tasks({ vendorConfig })) {
        console.log('\n');
        formattedConsoleLog(logSymbols.info, 'Starting task: ', name, '...');
        if (condition === undefined || condition) {
            formattedConsoleLog(logSymbols.success, 'Task: ', name, 'passed.');
            await task();
        } else formattedConsoleLog(logSymbols.warning, `Condition not met for task: ${name}. Skipping...`);
    }

    console.log('\n');
    return formattedConsoleLog(logSymbols.success, 'Tasks finished.');
})();
