import { Link } from 'react-router-dom';
import { ArrowSmallRightIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import usePages from '../../hooks/usePages.tsx';
import Button from '../../components/_base/Button';
import PrimaryText from '../../components/_base/PrimaryText';
import SecondaryText from '../../components/_base/SecondaryText';

const PiracyPolicyPage = () => {
    const { t } = useTranslation();
    const { brandName, customerSupport, piracyPolicy } = useCreatorConfig();
    const { homePage } = usePages();

    return (
        <PrimaryText className="py-16 px-4 flex flex-col justify-center items-center">
            <img src={piracyPolicy?.policeLogoUrl} className="w-48 mb-16" />
            <div className="text-3xl text-center font-black mb-2 flex items-center gap-4">
                <ExclamationTriangleIcon className="h-8 w-8" />
                <div>Piracy Policy</div>
            </div>
            <SecondaryText className="text-lg text-center mb-16 flex items-center">
                Piracy/theft of content will not be tolerated.
            </SecondaryText>

            <div className="mx-auto max-w-7xl px-6 lg:px-8 text-lg flex flex-col gap-8">
                <p>
                    <b>Important Warning:</b> Unauthorized Use of Content owned and made available to you by{' '}
                    <b>{brandName}</b> is a Crime.
                </p>
                <p>
                    Welcome to <b>{brandName}</b> (the "Platform"). This notice is a stern reminder of our commitment to
                    protecting intellectual property rights and the content available on our Platform. Please read this
                    notice carefully.
                </p>
                <p>
                    <b>Content Ownership</b>
                    <br />
                    All Intellectual Property rights surrounding the content, including but not limited to text, images,
                    videos, software, and any other materials made available to you on this Platform, are owned by{' '}
                    <b>{brandName}</b>.
                </p>
                <p>
                    <b>For Your Use Only</b>
                    <br />
                    Content provided on this Platform is intended for your personal, non-commercial use only. It is not
                    to be shared, distributed, or used for any other purpose without proper authorization.
                </p>
                <p>
                    <b>Prohibited Actions</b>
                    <br />
                    Copying, downloading, using, or sharing any content from this Platform without written authorization
                    from <b>{brandName}</b> is strictly prohibited. These actions violate intellectual property rights
                    and constitute piracy which is an eCrime.
                </p>
                <p>
                    <b>Reporting to {piracyPolicy?.regulationTerritory} Police</b>
                    <br />
                    Piracy is a serious offense under {piracyPolicy?.regulationTerritory}'s eCrime laws and regulations.
                    If you engage in unauthorized downloading, copying, use, or sharing of content from this Platform,
                    you are committing an eCrime.
                    <br />
                    <br />
                    Any unauthorized use, copying, downloading, or sharing of content from this Platform will be
                    classified as infringement of the <b>{brandName}</b> Intellectual Property and will be reported to
                    the {piracyPolicy?.regulationTerritory} Police and your local authorities for investigation and
                    legal action.
                </p>
                <p>
                    <b>Contact Us</b>
                    <br />
                    If you have any questions or concerns about this piracy notice or want to report an infringement of
                    our Intellectual Property, please contact us at{' '}
                    {customerSupport?.helpCenterUrl && (
                        <PrimaryText className="font-bold" isSelected>
                            <a href={customerSupport?.helpCenterUrl} target="_blank" className="underline">
                                {customerSupport?.helpCenterUrl}
                            </a>
                        </PrimaryText>
                    )}
                    {customerSupport?.email && (
                        <PrimaryText className="font-bold" isSelected>
                            <a href={`mailto:${customerSupport?.email}`} className="underline" target="_blank">
                                {customerSupport?.email}
                            </a>
                        </PrimaryText>
                    )}
                </p>
                <SecondaryText className="mt-8 text-lg text-center">
                    By using this Platform, you acknowledge that you have read and understood this piracy notice. We
                    take piracy seriously and will take legal action against any violations. Please enjoy the content
                    responsibly and within the boundaries of the law.
                </SecondaryText>
                <div className="flex justify-center">
                    <Link to={homePage?.path || '/'}>
                        <Button isPrimary size="xl">
                            <ArrowSmallRightIcon className="h-6 w-6" />
                            {t('Continue')}
                        </Button>
                    </Link>
                </div>
            </div>
        </PrimaryText>
    );
};

export default PiracyPolicyPage;
