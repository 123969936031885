import {Link, useLocation} from 'react-router-dom';
import * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {Bars3Icon, Cog6ToothIcon, LockClosedIcon, UserCircleIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import classNames from '../../../utils/classNames.ts';
import {NavigationType} from '../../../types/navigation.ts';
import {signOut, useAuth} from '../../../providers/AuthProvider';
import {useTranslation} from 'react-i18next';
import {useCreatorConfig} from '../../../providers/ConfigProvider';
import {DEFAULT} from '../../../_defaults/theme.ts';
import usePages from '../../../hooks/usePages.tsx';
import LanguageSelector from '../../LanguageSelector';
import PrimaryText from '../PrimaryText';
import SecondaryText from '../SecondaryText';
import addUrlSubstitutions from '../../../utils/addUrlSubstitutions.ts';
import useUserAccess from '../../../hooks/useUserAccess.tsx';
import getCommunityJoinLink from '../../../utils/getCommunityJoinLink.ts';
import PoweredByLogo from '../../PoweredByLogo';

// Handles local link, external link and on click navigation methods
const CustomLink = ({
                        to,
                        onClick,
                        className,
                        children,
                        style,
                    }: Omit<NavigationType, 'id' | 'name'> & React.HTMLProps<HTMLAnchorElement>) => {
    if (to) {
        return (
            <Link to={to} className={className} style={style} target={to?.indexOf('http') > -1 ? '_blank' : '_self'}>
                {children}
            </Link>
        );
    }


    return (
        <a onClick={onClick} className={classNames(className, 'cursor-pointer')} style={style}>
            {children}
        </a>
    );
};
const DashboardLayout = ({
                             isTopNavigation,
                             sideNavigation,
                             children,
                         }: {
    isTopNavigation?: boolean;
    sideNavigation?: 'left' | 'right';
    children: React.ReactNode;
}) => {
    const {vendorUuid, theme} = useCreatorConfig();
    const {t} = useTranslation();
    const {pathname: pathName} = useLocation();
    const {cognitoUser} = useAuth();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const {data: pages} = usePages();
    const {entitlements, userAccess, isLoading: isLoadingAccess} = useUserAccess();

    useEffect(() => {
        setIsSidebarOpen(false);
    }, [pathName]);

    const userNavigation = [
        {id: 'settings', name: t('Settings'), to: '/settings', icon: Cog6ToothIcon},
        {
            id: 'sign-out',
            name: t('Sign Out'),
            onClick: signOut,
            // icon: ArrowLeftOnRectangleIcon,
        },
    ] as NavigationType[];

    const [navigation, setNavigation] = useState([] as NavigationType[]);

    useEffect(() => {
        if (pages?.length > 0 && !isLoadingAccess && entitlements?.length > 0) {
            (async () => {
                setNavigation(
                    (
                        await Promise.all(
                            pages?.filter(({isNavigation, rules}) => {
                                if (!rules?.length) return isNavigation;
                                let isDisplayable = true;
                                const showRules = rules?.filter(rule => rule.type === 'show');
                                const hideRules = rules?.filter(rule => rule.type === 'hide');

                                // If any hide rule matches, hide the page immediately
                                const isHidden = hideRules?.some(rule => entitlements.includes(rule.entitlementId!));
                                if (isHidden) isDisplayable = false;

                                if (isDisplayable) {
                                    // If there are show rules, all of them must pass
                                    const allShowRulesPassed = showRules.every(rule => entitlements.includes(rule.entitlementId!));

                                    // If no show rules exist, show the page by default
                                    isDisplayable = showRules.length === 0 || allShowRulesPassed;
                                }

                                return isNavigation && isDisplayable;
                            })
                                ?.map(async (page) => {
                                    const extra = {to: `${page.path}`} as { to: string; isLocked?: boolean };

                                    if (page?.link?.type === 'external') {
                                        extra.to = await addUrlSubstitutions(page?.link?.target || '');
                                    }


                                    if (page?.link?.type === 'community') {
                                        if (userAccess?.find(({key}) => key === 'community.write')) {
                                            extra.to = await getCommunityJoinLink({
                                                vendorUuid,
                                                community: page?.link?.community!,
                                            });
                                        } else {
                                            extra.isLocked = true;
                                        }
                                    }


                                    return {
                                        id: page.SK,
                                        name: page.title,
                                        icon: page?.icon,
                                        isLocked: page?.isLocked || false,
                                        ...extra,
                                    };
                                }),
                        )
                    ).filter(Boolean) as NavigationType[],
                );
            })();
        }


    }, [pages, isLoadingAccess, entitlements]);

    const UserProfileInfo = () => (
        <Link to="/settings" className="hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out">
            <div className="flex flex-col justify-center items-center gap-2">
                {cognitoUser?.attributes?.picture ? (
                    <img
                        src={cognitoUser?.attributes?.picture}
                        className="h-24 w-24 rounded-full box-border"
                        style={{
                            border: `3px solid ${theme?.colours?.primaryColour}`,
                        }}
                    />
                ) : (
                    <UserCircleIcon className="h-16 w-16" aria-hidden="true"/>
                )}
                <div className="flex flex-col justify-center items-center gap-2">
                    <div className="flex items-center gap-2">
                        {cognitoUser?.attributes?.givenName && (
                            <PrimaryText className="font-semibold text-sm">
                                {`${cognitoUser?.attributes?.givenName ?? ''} ${
                                    cognitoUser?.attributes?.familyName ?? ''
                                }`.trim()}
                            </PrimaryText>
                        )}
                        {userAccess
                            ?.filter(({key}) => key === 'user.badge')
                            ?.map(({label}, index) => (
                                <div
                                    key={index}
                                    className="px-2 rounded-md text-sm"
                                    style={{
                                        backgroundColor: theme?.colours?.primaryColour,
                                        color: theme?.colours?.textOnPrimaryColour,
                                    }}>
                                    {label}
                                </div>
                            ))}
                    </div>
                    {cognitoUser?.attributes?.email !== 'shanice@genflow.com' && (
                        <SecondaryText className="text-xs">{cognitoUser?.attributes?.email}</SecondaryText>
                    )}
                </div>
            </div>
        </Link>
    );

    return (
        <div>
            <Transition.Root show={isSidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setIsSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-gray-900/80"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full">
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0">
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() => setIsSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div
                                    className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4"
                                    style={
                                        theme?.colours?.navigationColour2
                                            ? {
                                                background: `linear-gradient(${theme?.colours?.navigationColour}, ${theme?.colours?.navigationColour2})`,
                                            }
                                            : {background: theme?.colours?.navigationColour}
                                    }>
                                    <div className="flex h-16 justify-center items-center mt-6">
                                        <img className="h-8 w-auto" src={theme?.logos?.logoDark} alt="Genflow"/>
                                    </div>
                                    <UserProfileInfo/>
                                    <nav className="flex flex-1 flex-col">
                                        <div className="flex flex-1 flex-col gap-y-7">
                                            <div>
                                                <div role="list" className="space-y-1">
                                                    {navigation.map(({to, onClick, icon, isLocked, ...item}) => (
                                                        <div key={item.id}>
                                                            <CustomLink
                                                                {...(isLocked
                                                                    ? {}
                                                                    : {
                                                                        to,
                                                                        onClick,
                                                                    })}
                                                                style={
                                                                    pathName === to
                                                                        ? {
                                                                            color:
                                                                                theme?.colours
                                                                                    ?.navigationSelectedTextColour ||
                                                                                theme?.colours?.textOnPrimaryColour,
                                                                            ...(theme?.colours
                                                                                ?.navigationSelectedHighlightColour
                                                                                ? {
                                                                                    backgroundColor:
                                                                                    theme?.colours
                                                                                        ?.navigationSelectedHighlightColour,
                                                                                }
                                                                                : {}),
                                                                        }
                                                                        : {}
                                                                }
                                                                className={
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                }>
                                                                {icon && (
                                                                    <img
                                                                        className={'h-6 w-6 shrink-0'}
                                                                        aria-hidden="true"
                                                                        src={
                                                                            pathName === to
                                                                                ? icon.active
                                                                                : icon.inactive
                                                                        }
                                                                    />
                                                                )}
                                                                <PrimaryText
                                                                    className="flex-1"
                                                                    isSelected={pathName === to}
                                                                    style={
                                                                        pathName === to
                                                                            ? {
                                                                                color:
                                                                                    theme?.colours
                                                                                        ?.navigationSelectedTextColour ||
                                                                                    theme?.colours?.primaryColour,
                                                                            }
                                                                            : {}
                                                                    }>
                                                                    {item.name}
                                                                </PrimaryText>
                                                                {isLocked && (
                                                                    <LockClosedIcon
                                                                        className={'h-6 w-6 shrink-0'}
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                            </CustomLink>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <PoweredByLogo/>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            {sideNavigation && (
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div
                        className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4"
                        style={
                            theme?.colours?.navigationColour2
                                ? {
                                    background: `linear-gradient(${theme?.colours?.navigationColour}, ${theme?.colours?.navigationColour2})`,
                                }
                                : {background: theme?.colours?.navigationColour}
                        }>
                        <div className="flex h-16 justify-center items-center mt-6">
                            <img className="h-8 w-auto" src={theme?.logos?.logoDark} alt="Genflow"/>
                        </div>
                        <UserProfileInfo/>
                        <nav className="flex flex-1 flex-col">
                            <div role="list" className="flex flex-1 flex-col gap-y-7">
                                <div>
                                    <div role="list" className="space-y-1">
                                        {navigation.map(({to, onClick, icon, isLocked, ...item}) => (
                                            <div key={item.name}>
                                                <CustomLink
                                                    {...(isLocked
                                                        ? {}
                                                        : {
                                                            to,
                                                            onClick,
                                                        })}
                                                    style={
                                                        pathName === to
                                                            ? {
                                                                color:
                                                                    theme?.colours?.navigationSelectedTextColour ||
                                                                    theme?.colours?.textOnPrimaryColour,

                                                                ...(theme?.colours?.navigationSelectedHighlightColour
                                                                    ? {
                                                                        backgroundColor:
                                                                        theme?.colours
                                                                            ?.navigationSelectedHighlightColour,
                                                                    }
                                                                    : {}),
                                                            }
                                                            : {}
                                                    }
                                                    className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                                                    {icon && (
                                                        <img
                                                            className={'h-6 w-6 shrink-0'}
                                                            aria-hidden="true"
                                                            src={pathName === to ? icon.active : icon.inactive}
                                                        />
                                                    )}
                                                    <PrimaryText
                                                        className="flex-1"
                                                        isSelected={pathName === to}
                                                        style={
                                                            pathName === to
                                                                ? {
                                                                    color:
                                                                        theme?.colours
                                                                            ?.navigationSelectedTextColour ||
                                                                        theme?.colours?.primaryColour,
                                                                }
                                                                : {}
                                                        }>
                                                        {item.name}
                                                    </PrimaryText>
                                                    {isLocked && (
                                                        <LockClosedIcon
                                                            className={'h-6 w-6 shrink-0'}
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                </CustomLink>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <PoweredByLogo/>
                        </nav>
                    </div>
                </div>
            )}

            <div className="lg:pl-72">
                <div
                    className={classNames(
                        'sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8',
                        !isTopNavigation && 'flex lg:hidden',
                    )}
                    style={{
                        backgroundColor: theme?.colours?.backgroundColour || DEFAULT.BACKGROUND_COLOUR,
                    }}>
                    <button type="button" className="-m-2.5 p-2.5 lg:hidden" onClick={() => setIsSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                    <div className="lg:hidden absolute left-20 right-20 flex justify-center items-center">
                        <img className="h-6 object-contain" src={theme?.logos?.logoDark} alt="Genflow"/>
                    </div>

                    {isTopNavigation && (
                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                            <div className="relative flex flex-1">
                                {/*<label htmlFor="search-field" className="sr-only">*/}
                                {/*    Search*/}
                                {/*</label>*/}
                                {/*<MagnifyingGlassIcon*/}
                                {/*    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"*/}
                                {/*    aria-hidden="true"*/}
                                {/*/>*/}
                                {/*<input*/}
                                {/*    id="search-field"*/}
                                {/*    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"*/}
                                {/*    placeholder="Search..."*/}
                                {/*    type="search"*/}
                                {/*    name="search"*/}
                                {/*/>*/}
                            </div>
                            <div className="flex items-center gap-x-4 lg:gap-x-6">
                                <LanguageSelector/>

                                {/* Separator */}
                                <div
                                    className="hidden lg:block lg:h-6 lg:w-px"
                                    aria-hidden="true"
                                    style={{
                                        borderColor: theme?.colours?.secondaryColour,
                                    }}
                                />

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative">
                                    <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                        <span className="sr-only">Open user menu</span>

                                        {cognitoUser?.attributes?.picture ? (
                                            <img
                                                className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                                                src={cognitoUser?.attributes?.picture}
                                                alt="Profile Picture"
                                            />
                                        ) : (
                                            <UserCircleIcon className="h-8 w-8" aria-hidden="true"/>
                                        )}

                                        <span className="flex items-center">
                                            <div className="flex flex-col text-left ml-4 text-sm leading-6">
                                                <div className="font-semibold">
                                                    {`${cognitoUser?.attributes?.givenName ?? ''} ${
                                                        cognitoUser?.attributes?.familyName ?? ''
                                                    }`}
                                                </div>
                                                <div className="text-xs">{`${
                                                    cognitoUser?.attributes?.email ?? ''
                                                }`}</div>
                                            </div>
                                            <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true"/>
                                        </span>
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items
                                            className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none bg"
                                            style={{
                                                backgroundColor: theme?.colours?.secondaryColour,
                                            }}>
                                            {userNavigation.map(({to, onClick, icon, isLocked, ...item}) => (
                                                <Menu.Item key={item.name}>
                                                    {({active}) => (
                                                        <CustomLink
                                                            {...(isLocked
                                                                ? {}
                                                                : {
                                                                    to,
                                                                    onClick,
                                                                })}
                                                            style={
                                                                pathName === to
                                                                    ? {
                                                                        color: theme?.colours?.primaryColour,
                                                                    }
                                                                    : {}
                                                            }
                                                            className={classNames(
                                                                active ? 'bg-gray-50' : '',
                                                                'flex gap-x-3 px-3 py-1 text-sm leading-6',
                                                            )}>
                                                            {icon && (
                                                                <img
                                                                    className={'h-6 w-6 shrink-0'}
                                                                    aria-hidden="true"
                                                                    src={pathName === to ? icon.active : icon.inactive}
                                                                />
                                                            )}
                                                            <div className="flex-1">{item.name}</div>
                                                        </CustomLink>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    )}
                </div>

                <main className="py-5 lg:py-10">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">{children}</div>
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
