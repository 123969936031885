import { Habit } from '../../types/habit-tracker.ts';
import useHabitTracker from '../../hooks/useHabitTracker.ts';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classNames from '../../utils/classNames.ts';
import Loader from '../_base/Loader';
import PrimaryText from '../_base/PrimaryText';

const HabitCard = ({ habit, isCompleted }: { habit: Habit; isCompleted?: boolean }) => {
    const { trackHabit, isTrackingHabit } = useHabitTracker();
    return (
        <div
            className="flex flex-row items-center gap-4 border-2 border-[#FFFFFF1A] rounded-md p-4 cursor-pointer hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out"
            key={habit.habitUuid}
            onClick={() => trackHabit({ habitUuid: habit?.habitUuid as string })}>
            {habit?.imageUrl && <img src={habit?.imageUrl} className="w-4 h-4" />}
            <PrimaryText className="flex-1">{habit?.habitName}</PrimaryText>
            {isTrackingHabit ? (
                <Loader width={30} height={30} />
            ) : (
                <PrimaryText isSelected={isCompleted}>
                    <CheckCircleIcon className={classNames('h-8 w-8', !isCompleted && 'opacity-40')} />
                </PrimaryText>
            )}
        </div>
    );
};

export default HabitCard;
