import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import { browserName, browserVersion, mobileModel, mobileVendor } from 'react-device-detect';
import PrimaryText from '../_base/PrimaryText';

const VideoBrowserSupportModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { theme, customerSupport, thirdPartyIntegrations } = useCreatorConfig();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);

    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!isModalOpen) onClose();
    }, [isModalOpen]);

    return (
        <Transition.Root show={isModalOpen || false} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 sm:p-0 sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 lg:mx-4 sm:w-full sm:max-w-lg sm:p-6"
                                style={{
                                    backgroundColor: theme?.colours?.backgroundColour,
                                }}>
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setIsModalOpen(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start mt-6 md:mt-0">
                                    <div className="sm:ml-4 sm:mt-0">
                                        <Dialog.Title as="h3" className="font-semibold leading-6 text-xl">
                                            Using the recommended browser
                                        </Dialog.Title>
                                        <Dialog.Description as="h3" className="leading-6 text-xs">
                                            {`You are currently using: `}
                                            <strong
                                                style={{
                                                    color: theme?.colours?.primaryColour,
                                                }}>{`${mobileVendor} ${mobileModel} with ${browserName} (v${browserVersion})`}</strong>
                                        </Dialog.Description>
                                        <div className="mt-2 text-sm flex flex-col">
                                            <p>
                                                To protect the valuable content you purchased, you may notice a dynamic
                                                watermark of your name and email address overlaying the video, this
                                                deterrent enables protection of the content from piracy and illegal
                                                distribution - ultimately identifying any bad players
                                            </p>
                                            <p className="mt-4">
                                                For content protection security and privacy reasons, you must ensure
                                                your browser is updated to the latest version.
                                            </p>
                                            <p className="mt-4">
                                                The recommended browser depends on the device you are using:
                                            </p>
                                            <strong className="mt-4">On Desktop/Laptop (Mac)</strong>
                                            {thirdPartyIntegrations?.vdoCipher?.appleFairPlayStreamingConfigured ? (
                                                <>
                                                    <p>Use the latest version of Safari (default browser).</p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        {`Use the latest version of Chrome, you can download this at `}
                                                        <a href="https://www.google.com/chrome" className="underline">
                                                            https://www.google.com/chrome
                                                        </a>
                                                    </p>
                                                    <p className="mt-2">*Safari support coming soon</p>
                                                </>
                                            )}

                                            <strong className="mt-4">On Desktop/Laptop (Windows)</strong>
                                            <p>
                                                {`Use the latest version of Chrome, you can download this at `}
                                                <a href="https://www.google.com/chrome" className="underline">
                                                    https://www.google.com/chrome
                                                </a>
                                            </p>
                                            <strong className="mt-4">On Apple iPhone</strong>
                                            <p>Use the latest version of Safari (default browser).</p>
                                            <strong className="mt-4">On Android phone</strong>
                                            <p>Use the latest version of Chrome (default browser)*</p>
                                            <p className="text-xs">
                                                *In very few cases, Chrome may not work - in which case it is
                                                recommended to install the Firefox browser app.
                                            </p>
                                            <p className="mt-6 text-xs">
                                                Still not loading for you? Contact us at{' '}
                                                {customerSupport?.helpCenterUrl && (
                                                    <PrimaryText className="font-bold" isSelected>
                                                        <a
                                                            href={`${customerSupport?.helpCenterUrl}`}
                                                            className="underline"
                                                            target="_blank">
                                                            {customerSupport?.helpCenterUrl}
                                                        </a>
                                                    </PrimaryText>
                                                )}
                                                {customerSupport?.email && (
                                                    <PrimaryText className="font-bold" isSelected>
                                                        <a
                                                            href={`mailto:${customerSupport?.email}`}
                                                            className="underline">
                                                            {customerSupport?.email}
                                                        </a>
                                                    </PrimaryText>
                                                )}{' '}
                                                so that we can resolve this for you quickly!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default VideoBrowserSupportModal;
