import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import getDomainName from '../utils/getDomainName.ts';

const useDomainName = () => {
    const location = useLocation();
    const [domainName, setDomainName] = useState(null as string | null);

    useEffect(() => {
        const locationDomainName = getDomainName();
        if (domainName !== locationDomainName) setDomainName(locationDomainName as string);
    }, [location?.pathname]);
    return domainName;
};

export default useDomainName;
