import { useAuth } from '../../../providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import usePublicImageUpload from '../../../hooks/usePublicImageUpload.tsx';
import React, { useRef, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import classNames from '../../../utils/classNames.ts';
import moment from 'moment-timezone';
import ThemedSelect from '../../_base/ThemedSelect';
import Button from '../../_base/Button';
import SecondaryText from '../../_base/SecondaryText';
import PrimaryText from '../../_base/PrimaryText';
import getDomainName from '../../../utils/getDomainName.ts';

const SettingsList = ({ className, ...props }: { className?: string }) => {
    const { t } = useTranslation();
    const { cognitoUser, updateCognitoUserAttributes, signOut } = useAuth();
    const profilePictureFileRef = useRef<HTMLInputElement>(null);
    const { uploadPublicImage, uploadingPublicImage } = usePublicImageUpload();
    const { theme } = useCreatorConfig();

    const [isEditingName, setIsEditingName] = useState(false);
    const [firstName, setFirstName] = useState(cognitoUser?.attributes?.givenName);
    const [lastName, setLastName] = useState(cognitoUser?.attributes?.familyName);
    const [timezone, setTimezone] = useState(cognitoUser?.attributes?.zoneinfo);
    const [isUpdatingName, setIsUpdatingName] = useState(false);
    const [isUpdatingTimezone, setIsUpdatingTimezone] = useState(false);

    const timezonesOptions = moment.tz.names().map((value) => ({ label: value, value }));

    const UpdateButton = ({
        isLoading,
        isDisabled,
        onClick,
    }: {
        isLoading?: boolean;
        isDisabled?: boolean;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
    }) => (
        <Button isPrimary isLoading={isLoading} isDisabled={isDisabled} onClick={onClick}>
            {t('Update')}
        </Button>
    );

    const onProfilePictureInputChangeCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newImageFile = e.target?.files?.[0];
        if (newImageFile) {
            const imageUrl = await uploadPublicImage(newImageFile);
            // update cognito
            await updateCognitoUserAttributes({
                picture: imageUrl,
            });
        }
    };

    const onClickUploadImage = () => {
        if (!profilePictureFileRef?.current) return;

        profilePictureFileRef.current.click();
    };
    const onClickUpdateName = async () => {
        if (!isEditingName) {
            setFirstName(cognitoUser?.attributes?.givenName);
            setLastName(cognitoUser?.attributes?.familyName);
            return setIsEditingName(true);
        }

        if (!firstName) return alert('First name is required.');
        if (!lastName) return alert('Last name is required.');

        if (firstName !== cognitoUser?.attributes?.givenName || lastName !== cognitoUser?.attributes?.familyName) {
            setIsUpdatingName(true);
            await updateCognitoUserAttributes({
                given_name: firstName,
                family_name: lastName,
            });
            setIsUpdatingName(false);
        }

        setIsEditingName(false);
    };

    const onClickUpdateTimezone = async () => {
        if (!timezone) return alert('Timezone is required.');

        if (timezone !== cognitoUser?.attributes?.zoneinfo) {
            setIsUpdatingTimezone(true);
            await updateCognitoUserAttributes({
                zoneinfo: timezone,
            });
            setIsUpdatingTimezone(false);
        }

        setIsEditingName(false);
    };

    // const current../../../../ = supportedLngs.find(({ isoCode }) => isoCode === i18n.../../../../);

    return (
        <div className={classNames(className)} {...props}>
            <div className="flex flex-col gap-16">
                <div>
                    <PrimaryText block className="text-base font-semibold leading-7">
                        {t('Profile')}
                    </PrimaryText>
                    <SecondaryText block className="text-sm leading-6">
                        {t('Profile.Description')}
                    </SecondaryText>

                    <dl className="mt-2 space-y-6 -sm leading-6">
                        <div className="pt-6 sm:flex border-t" style={{
                            borderColor: theme?.colours?.secondaryColour,
                        }}>
                            <SecondaryText className="font-medium sm:w-64 sm:flex-none sm:pr-6">
                                {t('Photo')}
                            </SecondaryText>
                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                {cognitoUser?.attributes?.picture ? (
                                    <img
                                        className="h-8 w-8 rounded-full bg-gray-50 object-cover"
                                        src={cognitoUser?.attributes?.picture}
                                        alt="Profile Picture"
                                    />
                                ) : (
                                    <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
                                )}
                                <input
                                    type="file"
                                    ref={profilePictureFileRef}
                                    onChangeCapture={onProfilePictureInputChangeCapture}
                                    id="profilePicture"
                                    alt="+ Add Profile Picture"
                                    className="hidden"
                                    accept="image/*"
                                />
                                <UpdateButton onClick={onClickUploadImage} isLoading={uploadingPublicImage} />
                            </dd>
                        </div>
                        <div
                            className="pt-6 sm:flex"
                            style={{
                                borderColor: theme?.colours?.secondaryColour,
                            }}>
                            <SecondaryText className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                {t('Full name')}
                            </SecondaryText>
                            <dd
                                className="mt-1 flex-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                {isEditingName ? (
                                    <div className="flex flex-row gap-4">
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className="flex-1 relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            className="flex-1 relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                ) : (
                                    <PrimaryText>{`${cognitoUser?.attributes?.givenName ?? ''} ${
                                        cognitoUser?.attributes?.familyName ?? ''
                                    }`}</PrimaryText>
                                )}
                                <UpdateButton onClick={onClickUpdateName} isLoading={isUpdatingName} />
                            </dd>
                        </div>
                        <div
                            className="pt-6 sm:flex"
                            style={{
                                borderColor: theme?.colours?.secondaryColour,
                            }}>
                            <SecondaryText className="font-medium sm:w-64 sm:flex-none sm:pr-6">
                                {t('Email address')}
                            </SecondaryText>
                            <dd
                                className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                <PrimaryText>{cognitoUser?.attributes?.email}</PrimaryText>
                                {/*<button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                                {/*    Update*/}
                                {/*</button>*/}
                            </dd>
                        </div>
                    </dl>
                </div>
                <div>
                    <PrimaryText
                        block
                        className="text-base font-semibold leading-7">
                        Functionality
                    </PrimaryText>
                    <SecondaryText className="text-sm leading-6">
                        These settings are used to customise your user experience.
                    </SecondaryText>

                    <dl className="mt-2 space-y-6 -sm leading-6">
                        <div
                            className="pt-6 sm:flex border-t"
                            style={{
                                borderColor: theme?.colours?.secondaryColour,
                            }}>
                            <SecondaryText className="font-medium  sm:w-64 sm:flex-none sm:pr-6">
                                Timezone
                            </SecondaryText>
                            <dd
                                className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                <ThemedSelect
                                    defaultValue={timezone && { value: timezone, label: timezone }}
                                    onChange={(e) => e && setTimezone(e.value)}
                                    options={timezonesOptions}
                                    isSearchable
                                    classNames={{
                                        container: () => '!w-64',
                                    }}
                                    placeholder="Select timezone..."
                                />
                                <UpdateButton
                                    onClick={onClickUpdateTimezone}
                                    isLoading={isUpdatingTimezone}
                                    isDisabled={timezone === cognitoUser?.attributes?.zoneinfo}
                                />
                            </dd>
                        </div>
                    </dl>
                </div>

                <div>
                    <PrimaryText
                        block
                        className="text-base font-semibold leading-7">
                        Billing
                    </PrimaryText>
                    <SecondaryText className="text-sm leading-6">
                        Manage your billing information and payment methods.
                    </SecondaryText>

                    <dl className="mt-2 space-y-6 -sm leading-6">
                        <div
                            className="pt-6 sm:flex border-t"
                            style={{
                                borderColor: theme?.colours?.secondaryColour,
                            }}>
                            <a href={`https://${getDomainName().replace('app.', 'checkout.')}/manage-billing`} target="_blank">
                                <Button isPrimary>Manage Billing</Button>
                            </a>
                        </div>
                    </dl>
                </div>
            </div>
            <PrimaryText className="cursor-pointer underline ml-auto mt-20" onClick={signOut}>
                Sign Out
            </PrimaryText>
        </div>
    );
};

export default SettingsList;
