import { Link, useNavigate, useParams } from 'react-router-dom';
import { CourseProductLesson, CourseProductSection } from '../../../types/course-product.ts';
import useContent from '../../../hooks/useContent.tsx';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import useContentAnalytics from '../../../hooks/useContentAnalytics.tsx';
import CourseProgressCard from '../../../components/CourseProgressCard';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import AssetItem from '../../../components/AssetItem';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import classNames from '../../../utils/classNames.ts';
import PageLoader from '../../_pages/PageLoader';
import Pill from '../../_base/Pill';
import PrimaryText from '../../_base/PrimaryText';
import SecondaryText from '../../_base/SecondaryText';
import { useEffect } from 'react';
import isVideoComplete from '../../../utils/isVideoComplete.ts';

const CourseModules = ({ className, ...props }: { className?: string }) => {
    const { t } = useTranslation();
    const { theme } = useCreatorConfig();
    const navigate = useNavigate();
    const { courseId } = useParams();
    const { data, isLoading } = useContent({ type: 'course', productId: courseId! });
    const { totalLessons } = useContentAnalytics({ productId: courseId! });

    const course = data!;
    const modules = orderBy(data?.sections, ['order'], ['asc']);
    const metaData = course?.meta_data && JSON.parse(course?.meta_data || '{}');
    const { data: contentAnalytics } = useContentAnalytics({
        productId: courseId!,
    });

    useEffect(() => {
        if (modules?.length === 1) {
            const lessons = orderBy(modules[0].lessons, ['order'], ['asc']);
            const firstUnwatchedLesson = lessons?.find((lesson) => {
                const videoAsset = lesson?.assets?.find(({ asset_type }) => asset_type.name === 'Secure Video');
                const analytics = contentAnalytics?.find(
                    ({ productId, contentId }) => productId === courseId && contentId === lesson.id,
                );
                return !isVideoComplete({
                    lastVideoPlayTimeSeconds: analytics?.lastVideoPlayTimeSeconds || 0,
                    videoLengthSeconds: videoAsset?.length_seconds || 0,
                });
            });

            navigate(
                `/courses/${courseId}/modules/${modules[0].id}${
                    modules[0].lessons?.[0]?.id ? `/lessons/${firstUnwatchedLesson?.id || lessons[0].id}` : ''
                }`,
                {
                    replace: true,
                },
            );
        }
    }, [modules, courseId]);

    // Finds any lesson which has a thumbnail to use
    const getModuleThumbnail = (module: CourseProductSection) => {
        const moduleMetaData = JSON.parse(module?.meta_data || '{}');
        if (moduleMetaData?.thumbnailUrl) return moduleMetaData?.thumbnailUrl;

        const getSecureVideoAsset = (lesson: CourseProductLesson) =>
            lesson?.assets?.find(({ asset_type }) => asset_type.name === 'Secure Video');
        const lessonWithThumbnail = module?.lessons?.find((lesson) => getSecureVideoAsset(lesson)?.thumbnail);

        if (lessonWithThumbnail) return getSecureVideoAsset(lessonWithThumbnail)?.thumbnail;
        return '';
    };

    return (
        <div className={classNames(className)} {...props}>
            <PageLoader inline isLoading={!data || isLoading || modules?.length <= 1}>
                <div className="flex flex-col gap-2">
                    <CourseProgressCard courseId={courseId!} />
                    <div className="w-full mt-4 flex flex-col-reverse lg:flex-row gap-4">
                        <div className="flex-1">
                            <div className="flex flex-col gap-4">
                                {modules?.map((module) => {
                                    const isLocked = !module?.lessons?.find(
                                        (lesson) =>
                                            lesson.assets?.find(({ asset_type }) => asset_type.name === 'Secure Video'),
                                    );
                                    return (
                                        <Link
                                            className="hover:lg:-translate-y-2 lg:duration-300 lg:ease-in-out"
                                            to={isLocked ? '' : `/courses/${courseId}/modules/${module.id}`}
                                            key={module.id}>
                                            <div
                                                className="flex flex-row items-center cursor-pointer gap-2 p-4 rounded-xl border"
                                                style={{
                                                    backgroundColor: theme?.colours?.secondaryColour,
                                                    borderColor: theme?.colours?.secondaryColour,
                                                }}>
                                                <div
                                                    className="aspect-video w-[150px] rounded-xl overflow-hidden relative border"
                                                    style={{
                                                        borderColor: theme?.colours?.secondaryColour,
                                                        backgroundColor: theme?.colours?.backgroundColour,
                                                    }}>
                                                    <img
                                                        src={getModuleThumbnail(module)}
                                                        className="object-cover object-center absolute"
                                                    />
                                                    {isLocked && (
                                                        <LockClosedIcon className="h-12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute opacity-80" />
                                                    )}

                                                    {/*<div*/}
                                                    {/*    className="absolute top-1/2 left-2 -translate-y-1/2 text-6xl opacity-10"*/}
                                                    {/*    style={{*/}
                                                    {/*        color: theme?.colours?.primaryColour,*/}
                                                    {/*    }}>*/}
                                                    {/*    {index + 1}*/}
                                                    {/*</div>*/}
                                                </div>

                                                <div className="flex-1 flex flex-col">
                                                    <PrimaryText inlineBlock className="text-base font-semibold">
                                                        {module.name}
                                                    </PrimaryText>
                                                    {module?.description && (
                                                        <SecondaryText
                                                            className="text-sm"
                                                            dangerouslySetInnerHTML={{ __html: module.description }}
                                                        />
                                                    )}
                                                    {!isLocked && (
                                                        <div className="mt-2 flex items-center gap-x-4 text-xs">
                                                            <Pill
                                                                label={`${module.lessons.length} ${t('lessons')}`}
                                                                size="xs"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="lg:w-[300px]">
                            <PrimaryText inlineBlock className="text-lg font-semibold mb-4">
                                What's included?
                            </PrimaryText>
                            <SecondaryText
                                inlineBlock
                                className="mb-4"
                                dangerouslySetInnerHTML={{ __html: course?.description || '' }}
                            />
                            <div className="flex flex-row flex-wrap gap-4">
                                <Pill label={`${modules?.length} ${t('modules')}`} />
                                <Pill label={`${totalLessons} ${t('lessons')}`} />
                            </div>
                            {metaData?.assets?.length > 0 && (
                                <div className="mt-4">
                                    {metaData.assets.map(({ title, url }: { title: string; url: string }) => (
                                        <AssetItem asset={{ assetType: { name: 'file' }, title, url }} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </PageLoader>
        </div>
    );
};

export default CourseModules;
