import useHabitTracker from '../../hooks/useHabitTracker.ts';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';
import Loader from '../_base/Loader';
import PrimaryText from '../_base/PrimaryText';

const HabitTrackerMilestones = () => {
    const { activeHabitGroup, isLoading } = useHabitTracker();

    const milestones = [7, 14, 21].map((days) => ({
        title: `${days} Days Completed`,
        isCompleted: activeHabitGroup?.lastStreak >= days,
    }));

    if (isLoading)
        return (
            <div className="flex-1 border-2 border-[#FFFFFF1A] rounded-md p-4 flex justify-center items-center">
                <Loader />
            </div>
        );

    return (
        <div className="flex-1 border-2 border-[#FFFFFF1A] rounded-md p-4">
            <PrimaryText inlineBlock className="text-lg font-semibold mb-2">
                Milestones
            </PrimaryText>
            <div className="grid grid-cols-2 gap-4">
                {milestones?.map(({ title, isCompleted }, index) => (
                    <div
                        className="border-2 border-[#FFFFFF1A] rounded-md p-4 flex flex-row items-center justify-center gap-2"
                        key={index}>
                        <div className="flex-1 flex flex-col">
                            <PrimaryText isSelected={isCompleted} className="text-sm">
                                {title}
                            </PrimaryText>
                            <PrimaryText isSelected={isCompleted} className="text-xs">
                                Milestone
                            </PrimaryText>
                        </div>
                        <div className="rounded-full border-2 border-[#FFFFFF1A] p-2">
                            {isCompleted ? (
                                <PrimaryText isSelected>
                                    <LockOpenIcon className={'h-3 w-3'} />
                                </PrimaryText>
                            ) : (
                                <PrimaryText>
                                    <LockClosedIcon className={'h-3 w-3'} />
                                </PrimaryText>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HabitTrackerMilestones;
