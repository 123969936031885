import { Link } from 'react-router-dom';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import usePages from '../../hooks/usePages.tsx';
import Button from '../../components/_base/Button';

const NotFoundPage = () => {
    const { theme } = useCreatorConfig();
    const { homePage } = usePages();

    return (
        <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold" style={{ color: theme?.colours?.primaryColour }}>
                    404
                </p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Link to={homePage?.path || '/'}>
                        <Button isPrimary>Go back home</Button>
                    </Link>
                </div>
            </div>
        </main>
    );
};

export default NotFoundPage;
