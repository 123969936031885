import { useQuery } from '@apollo/client';
import getUserContentAnalyticsQuery from '../api/content-analytics/getUserContentAnalyticsQuery.graphql';
import { useCreatorConfig } from '../providers/ConfigProvider';
import useContent from './useContent.tsx';
import { CourseProductLesson } from '../types/course-product.ts';
import { ContentAnalyticsData, LatestWatchedLesson } from '../types/content-analytics.ts';
import { orderBy } from 'lodash';

const useContentAnalytics = (params?: { productId: string; contentId?: string }) => {
    const { vendorUuid } = useCreatorConfig();

    const { data, loading, refetch } = useQuery(getUserContentAnalyticsQuery, {
        variables: {
            vendorUuid,
            ...(params?.productId ? { productId: params.productId } : {}),
            ...(params?.contentId ? { contentId: params.contentId } : {}),
        },
        skip: !vendorUuid,
    });
    const contentAnalytics = data?.getUserContentAnalytics?.data as ContentAnalyticsData[];

    const { data: contentData } = useContent({
        type: 'course',
        productId: params?.productId || contentAnalytics?.[0]?.productId,
    });

    if (loading) return { isLoading: true };

    const lessons = orderBy(contentData?.sections, ['order'], ['asc'])?.reduce(
        (acc: CourseProductLesson[], section) => [
            ...acc,
            ...(orderBy(section?.lessons, ['order'], ['asc'])
                ?.map((lesson) => ({ section, ...lesson }))
                ?.slice() ?? []),
        ],
        [],
    );

    const reversedLessons = lessons?.slice()?.reverse();

    const modules = orderBy(contentData?.sections, ['order'], ['asc']);

    const getAnalyticsForVideo = (videoId: string) =>
        (videoId && contentAnalytics?.find(({ contentId }) => contentId === videoId)) as ContentAnalyticsData;

    let latestWatchedLesson =
        contentAnalytics && (reversedLessons?.find((lesson) => getAnalyticsForVideo(lesson.id)) as LatestWatchedLesson);

    if (!latestWatchedLesson) latestWatchedLesson = lessons?.[0] as LatestWatchedLesson;

    if (latestWatchedLesson)
        latestWatchedLesson = {
            ...latestWatchedLesson,
            assets: orderBy(latestWatchedLesson?.assets, ['order'], ['asc']),
            analytics: getAnalyticsForVideo(latestWatchedLesson.id),
        };

    const latestWatchedLessonIndex = latestWatchedLesson
        ? lessons?.findIndex((lesson) => lesson.id === latestWatchedLesson.id)
        : 0;
    const totalLessons = modules?.reduce((acc, { lessons }) => acc + lessons.length, 0);

    const latestWatchedLessonPosition = latestWatchedLessonIndex ? latestWatchedLessonIndex + 1 : 0;
    const courseProgressPercentage =
        totalLessons > 0 ? Math.ceil((latestWatchedLessonPosition * 100) / totalLessons) : 0;

    return {
        data: contentAnalytics,
        refetch,
        latestWatchedLesson,
        latestWatchedLessonPosition,
        totalLessons,
        courseProgressPercentage,
    };
};

export default useContentAnalytics;
