import { keys } from 'lodash';
import environmentConfig, { domainName, hostName } from '../environment-config';
import Cookies from 'universal-cookie';

export default () => {
    const cookiesOptions = {
        path: '/',
        domain: hostName === 'localhost' ? 'localhost' : `.${domainName}`,
        secure: hostName !== 'localhost',
    };
    const cookies = new Cookies(null, { path: '/' });

    keys(cookies.getAll())
        .filter((key) =>
            key.startsWith(`CognitoIdentityServiceProvider.${environmentConfig.cognito.userPoolWebClientId}.`),
        )
        .forEach((key) =>
            cookies.remove(key, cookiesOptions),
        );
};
