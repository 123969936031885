import { PageBlock } from '../../../types/pages.ts';
import MediaCarousel from '../../_blocks/MediaCarousel';
import CoursesList from '../../_blocks/CoursesList';
import ResourcesList from '../../_blocks/ResourcesList';
import CourseModules from '../../_blocks/CourseModules';
import CourseModule from '../../_blocks/CourseModule';
import ResourcesModules from '../../_blocks/ResourcesModules';
import ResourcesModule from '../../_blocks/ResourcesModule';
import HabitTracker from '../../_blocks/HabitTracker';
import ResumeWatchingCard from '../../_blocks/ResumeWatchingCard';
import ImageCard from '../../_blocks/ImageCard';
import TextCard from '../../_blocks/TextCard';
import SettingsList from '../../_blocks/SettingsList';
import IFrameView from '../../_blocks/IFrameView';

const PageBlock = ({ block }: { block: PageBlock }) => (
    <>
        {block?.type === 'media-carousel' && (
            <MediaCarousel title={block?.title} block={block} className={block?.classNames} />
        )}
        {block?.type === 'courses-list' && <CoursesList className={block?.classNames} block={block} />}
        {block?.type === 'resources-list' && <ResourcesList className={block?.classNames} block={block} />}
        {block?.type === 'course-modules-list' && <CourseModules className={block?.classNames} />}
        {block?.type === 'course-module-view' && <CourseModule className={block?.classNames} />}
        {block?.type === 'resources-modules-list' && <ResourcesModules className={block?.classNames} />}
        {block?.type === 'resources-module-list' && <ResourcesModule className={block?.classNames} />}
        {block?.type === 'habit-tracker-view' && <HabitTracker className={block?.classNames} block={block} />}

        {block?.type === 'container-block' && (
            <div className={block?.classNames}>
                {block?.blocks?.map((childBlock) => <PageBlock block={childBlock} key={childBlock?.SK} />)}
            </div>
        )}
        {block?.type === 'resume-watching-card' && (
            <ResumeWatchingCard className={block?.classNames} title={block?.title} />
        )}
        {block?.type === 'image-card' && <ImageCard className={block?.classNames} block={block} title={block?.title} />}
        {block?.type === 'text-card' && <TextCard className={block?.classNames} block={block} />}
        {block?.type === 'settings-list' && <SettingsList className={block?.classNames} />}
        {block?.type === 'iframe-view' && <IFrameView className={block?.classNames} block={block} />}
    </>
);

export default PageBlock;
