import { useQuery } from '@apollo/client';
import getContentCourseQuery from '../api/content/getContentCourseQuery.graphql';
import { useCreatorConfig } from '../providers/ConfigProvider';
import useUserAccess from './useUserAccess.tsx';
import { CourseProduct } from '../types/course-product.ts';

const useContent = ({ type, productId }: { type: 'course'; productId: string }) => {
    const { vendorUuid } = useCreatorConfig();
    const { hasAccess } = useUserAccess({ contentId: productId });
    const contentQuery = { course: getContentCourseQuery }[type];
    const { data, loading } = useQuery(contentQuery, {
        variables: {
            vendorUuid,
            productId,
        },
        skip: !vendorUuid || !type || !productId || hasAccess === undefined,
    });

    if (loading) return { isLoading: true, data: null } as { data: CourseProduct | null; isLoading: boolean };

    const contentData =
        data?.[
            {
                course: 'getContentCourse',
            }[type]
        ]?.data;

    return { data: contentData, isLoading: false } as { data: CourseProduct; isLoading: boolean };
};

export default useContent;
