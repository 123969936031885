import classNames from '../../../utils/classNames.ts';
import { PageBlock } from '../../../types/pages.ts';
import { Link } from 'react-router-dom';
import { useCreatorConfig } from '../../../providers/ConfigProvider';

const TextCard = ({ block, className, ...props }: { block: PageBlock; className?: string }) => {
    const { theme } = useCreatorConfig();
    const Card = () => (
        <div className={classNames(className)} {...props}>
            {block?.data?.[0]?.title && (
                <div className="text-xl" style={{ color: theme?.colours?.primaryTextColour }}>
                    {block?.data?.[0]?.title}
                </div>
            )}
            {block?.data?.[0]?.subtitle && (
                <div className="text-base" style={{ color: theme?.colours?.secondaryTextColour }}>
                    {block?.data?.[0]?.subtitle}
                </div>
            )}
        </div>
    );

    if (['external', 'file']?.indexOf(block?.link?.type as string) > -1)
        return (
            <a
                href={block?.link?.target}
                {...(block?.link?.type === 'file' ? { download: true, target: '_blank' } : { target: '_blank' })}>
                <Card />
            </a>
        );
    if (block?.link?.type === 'internal')
        return (
            <Link to={block?.link?.target as string}>
                <Card />
            </Link>
        );

    return <Card />;
};

export default TextCard;
