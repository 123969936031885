import { ReactNode } from 'react';
import Loader from '../../_base/Loader';
import classNames from '../../../utils/classNames.ts';

const PageLoader = ({
    isLoading = false,
    inline = false,
    children,
}: {
    isLoading?: boolean;
    inline?: boolean;
    children?: ReactNode;
}) =>
    isLoading ? (
        <div
            className={classNames(
                `w-full flex items-center justify-center`,
                !inline && 'h-screen absolute top-0 right-0 bottom-0 left-0',
                inline && 'h-[50vh]',
            )}>
            <Loader />
        </div>
    ) : (
        children
    );

export default PageLoader;
