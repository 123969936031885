import Select, { GroupBase, Props } from 'react-select';
import { useCreatorConfig } from '../../../providers/ConfigProvider';
import classNames from '../../../utils/classNames.ts';

function ThemedSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
    classNames: propsClassNames,
    ...props
}: Props<Option, IsMulti, Group>) {
    const { theme } = useCreatorConfig();

    return (
        <Select
            styles={{
                control: (styles, { isFocused }) => ({
                    ...styles,
                    backgroundColor: theme?.colours?.backgroundColour,
                    border: isFocused ? '1px' : 0,
                    ...(isFocused ? { borderColor: theme?.colours?.primaryColour } : {}),
                }),
                menu: (styles) => ({
                    ...styles,
                    backgroundColor: theme?.colours?.backgroundColour,
                }),
                option: (styles, { isFocused, isSelected }) => ({
                    ...styles,
                    backgroundColor: isFocused || isSelected ? theme?.colours?.primaryColour : theme?.colours?.backgroundColour,
                    color: isFocused || isSelected ? theme?.colours?.textOnPrimaryColour : theme?.colours?.primaryTextColour,
                }),
                singleValue: (styles) => ({
                    ...styles,
                    color: theme?.colours?.primaryTextColour,
                }),
                placeholder: (styles) => ({
                    ...styles,
                    color: theme?.colours?.secondaryTextColour,
                }),
                input: (styles) => ({
                    ...styles,
                    color: theme?.colours?.primaryTextColour,
                }),
                noOptionsMessage: (styles) => ({
                    ...styles,
                    color: theme?.colours?.primaryTextColour,
                }),
            }}
            classNames={{
                container: (event) =>
                    classNames('w-full', propsClassNames?.container && propsClassNames?.container(event)),
                singleValue: (event) =>
                    classNames('text-base', propsClassNames?.singleValue && propsClassNames?.singleValue(event)),
                placeholder: (event) =>
                    classNames('text-base', propsClassNames?.placeholder && propsClassNames?.placeholder(event)),
            }}
            {...props}
        />
    );
}

export default ThemedSelect;
