import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useCreatorConfig } from '../../providers/ConfigProvider';
import PrimaryText from '../_base/PrimaryText';

const SignInIssuesModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { theme, customerSupport } = useCreatorConfig();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);

    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!isModalOpen) onClose();
    }, [isModalOpen]);

    return (
        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 sm:p-0 sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 lg:mx-4 w-full lg:max-w-lg sm:p-6"
                                style={{
                                    backgroundColor: theme?.colours?.backgroundColour,
                                }}>
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setIsModalOpen(false)}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start mt-6 md:mt-0">
                                    <div className="sm:ml-4 sm:mt-0">
                                        <Dialog.Title as="h3" className="font-semibold leading-6 text-xl">
                                            Resolving sign in issues
                                        </Dialog.Title>

                                        <div className="mt-2 text-sm flex flex-col">
                                            <p>
                                                If you are facing any sign in issues, there may be some simple steps to
                                                resolve this.
                                            </p>
                                            <strong className="mt-4">Incorrect Computer Clock Time</strong>
                                            <p>
                                                For security reasons (to prevent{' '}
                                                <a
                                                    href="https://en.wikipedia.org/wiki/Replay_attack"
                                                    target="_blank"
                                                    className="underline"
                                                    style={{
                                                        color: theme?.colours?.primaryColour,
                                                    }}>
                                                    replay attacks
                                                </a>
                                                ), your computer clock MUST be in sync and the best way to do this is to
                                                set it to automatic.
                                            </p>
                                            <p className="mt-2">
                                                If you have manually selected your clock time, it could have drifted out
                                                of sync and that is why you may be facing sign in issues.
                                            </p>
                                            <p className="mt-2">
                                                Set your computer clock to automatic, refresh this page and try again.
                                            </p>
                                            <p className="mt-6 text-xs">
                                                Still not able to sign in? Contact us at{' '}
                                                {customerSupport?.helpCenterUrl && (
                                                    <PrimaryText className="font-bold" isSelected>
                                                        <a
                                                            href={`${customerSupport?.helpCenterUrl}`}
                                                            className="underline">
                                                            {customerSupport?.helpCenterUrl}
                                                        </a>
                                                    </PrimaryText>
                                                )}
                                                {customerSupport?.email && (
                                                    <PrimaryText className="font-bold" isSelected>
                                                        <a
                                                            href={`mailto:${customerSupport?.email}`}
                                                            className="underline">
                                                            {customerSupport?.email}
                                                        </a>
                                                    </PrimaryText>
                                                )}{' '}
                                                so that we can resolve this for you quickly!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default SignInIssuesModal;
