import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

export const browserRouter = createBrowserRouter([
    {
        path: '*',
        element: <App />,
    },
]);

Bugsnag.start({
    apiKey: 'a66e7e95e04f9bf960b17d6a09ca0b41',
    plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({
    apiKey: 'a66e7e95e04f9bf960b17d6a09ca0b41',
});
const BugsnagPlugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
const BugsnagErrorBoundary = BugsnagPlugin.createErrorBoundary(React);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BugsnagErrorBoundary>
            <RouterProvider router={browserRouter} />
        </BugsnagErrorBoundary>
    </React.StrictMode>,
);
